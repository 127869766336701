import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, Theme } from "@material-ui/core";

const padding = 10;

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%",
        marginTop: 15,
        marginBottom: 55
    },
    text: {
        textAlign: "center",
        fontSize: "18px",
        fontWeight: "normal",
        color: "#777777",
        lineHeight: 1.8
    }
}));

interface Props {
}

const Introduction = (props: Props) => {
    const classes = useStyles(props)();

    return (
        <div className={classes.root}>
            <Typography className={classes.text} variant="body1">Hello! I am Teun Kooijman, an experienced software engineer specializing in back-end development. Experienced with all stages of the development cycle for web- and mobile projects, and with a strong background in software development and project management. Well-versed in numerous languages and frameworks, including ASP.NET, Spring Boot, React and Android.</Typography>
        </div>
    );
}

export default Introduction;