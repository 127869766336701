import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import LogoDiamondAgile from "../../../../../resources/logos/LogoDiamondAgile"
import LogoHiEfficiency from "../../../../../resources/logos/LogoHiEfficiency";
import LogoInnovum from "../../../../../resources/logos/LogoInnovum";
import LogoSilvesterConsultancy from "../../../../../resources/logos/LogoSilvesterConsultancy";
import LogoGemCloud from "../../../../../resources/logos/LogoGemCloud";
import LogoEquineInstruments from "../../../../../resources/logos/LogoEquineInstruments";
import LogoNwo from "../../../../../resources/logos/LogoNwo";
import LogoGemboxx from "../../../../../resources/logos/LogoGemboxx";
import ExperienceTimelineCardContent, { ExperienceTimelineCardContent_Props } from "./ExperienceTimelineCardContent";
import Timeline from "../../Timeline";
import { withScrollableAnchor, WithScrollableAnchor_Props_Injected } from "../../../../generic/scroll_anchor/WithScrollableAnchor";

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%"
    },
}));


interface Props extends WithScrollableAnchor_Props_Injected {

}

const CHIP_DOTNET_CORE = ".NET Core";
const CHIP_DOTNET_FRAMEWORK = ".NET Framework";
const CHIP_REACT = "React";
const CHIP_SPRING_BOOT = "Spring Boot";
const CHIP_REACT_NATIVE = "React Native";
const CHIP_NATIVE_ANDROID = "Native Android";
const CHIP_NATIVE_IOS = "Native iOS";
const CHIP_GREXX = "Grexx";
const CHIP_AZURE = "Azure";

const ExperienceTimeline = (props: Props) => {
    const classes = useStyles(props)();

    const experiences: Array<ExperienceTimelineCardContent_Props> = [
        { chips: [CHIP_DOTNET_CORE, CHIP_REACT, CHIP_SPRING_BOOT], logo: LogoDiamondAgile, timespan: "February 2019 - Now", function: "Full Stack Developer", description: "Lead developer on the Lightning Assessment web applications, a large ETL product used to assess the agility of a software development process. During development we were able to rapidly prototype using Docker, Heroku and CI/CD pipelines deploying all the way to production. This large greenfield project made it from inception to market in four months time." },
        { chips: [CHIP_REACT_NATIVE], logo: LogoHiEfficiency, timespan: "July 2018 - February 2019", function: "Hybrid Mobile Developer", description: "Lead developer on the on the Hi Efficiency application, a mobile application used to measure Process Efficiency in software development teams. After I joined the development team, I introduced a new underlying architecture, design- and development philosophy, increasing velocity many times over." },
        { chips: [CHIP_DOTNET_FRAMEWORK, CHIP_DOTNET_CORE], logo: LogoInnovum, timespan: "April 2017 - August 2018", function: "Back-End Developer", description: "Lead devleoper on the company's implementation of governmental data provisioning systems, which store data from and provide queryable interfaces for all 11 basic governmental data registration systems. In this solution we stored billions of records and automatically kept them up to date by processing governmental transaction messages, while providing an extensible OData REST API for our clients." },
        { chips: [CHIP_NATIVE_ANDROID, CHIP_NATIVE_IOS, CHIP_REACT, CHIP_DOTNET_CORE], logo: LogoSilvesterConsultancy, timespan: "April 2017 - August 2017", function: "Full Stack Developer", description: "Core developer of a mobile veterinary formulary, assisting veterinarians with diagnosing the animal, devising and executing a treatment plan, and calculating medicinal dosages. The application, as well as the back-end management portal, is now used daily by hundreds of veterinarians across the country." },
        { chips: [CHIP_DOTNET_FRAMEWORK, CHIP_GREXX], logo: LogoGemCloud, timespan: "March 2016 - July 2017", function: "Scrum Master & Back-End Developer", description: "Scrum Master and active part of the development team for a new municipal subsidy system, allowing the government to process and monitor subsidy accountability processes from different business domains. The acquisition was met with a lot of resistance from within many different levels of within the organization, but made its debut at the start of the 2018/2019 subsidy year." },
        { chips: [CHIP_NATIVE_ANDROID, CHIP_NATIVE_IOS], logo: LogoEquineInstruments, timespan: "February 2015 - June 2015", function: "Native Mobile Developer", description: "The development of an equine gait classification algorithm, employing signal processing over the accelerometer, gyroscope and magnetometer within the IMU of a modern smartphone. The resulting algorithm turned out to determine the gait of a horse with 99.96% accuracy. This algorithm was then used in a smartphone application that assisted equestrians with their training routines." },
        { chips: [CHIP_NATIVE_ANDROID, CHIP_NATIVE_IOS, CHIP_DOTNET_FRAMEWORK, CHIP_REACT, CHIP_AZURE], logo: LogoEquineInstruments, timespan: "October 2015 - August 2018", function: "Founder & Full Stack Developer", description: "Founder of a subsidiary in which we developed software and hardware for use within the world of equestrian sports. In the project I was responsible for designing and implementing the entire web- and mobile architecture, and was responsible for keeping in contact with our external partners." },
        { chips: [CHIP_NATIVE_ANDROID], logo: LogoNwo, timespan: "December 2014 - August 2016", function: "Native Android Developer", description: "One of two core developers for the Smart View Through Glass (SVTG) platform, allowing developers to easily couple performance sensors to an Android smartphone and various smartglasses such as Google Glass and Recon Jet. Using the library, we've created applications for rowing, horseback riding, (ice-) skating, medical rehabilitation, robotics and cycling." },
        { chips: [CHIP_DOTNET_FRAMEWORK, CHIP_GREXX], logo: LogoGemboxx, timespan: "December 2013 - November 2016", function: "Full Stack Developer", description: "Part of a development team for various information systems for Dutch governments and municipalities, such as systems for civil affairs offices, appeal- and objection offices, and youth care institutions. Since the end of 2014 I have also been responsible for managing various teams who work on these systems, while remaining an active part of the development team." },
    ];

    const items = experiences.map(experience => <ExperienceTimelineCardContent {...experience} />)

    return (
        <div className={classes.root}>
            <Timeline items={items} title="Work Experience" />
        </div>
    );
}

export default withScrollableAnchor(ExperienceTimeline);