import React from "react";
import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";

export const offset_top = 40;

const useStyles = (props: EducationTimelineCardContent_Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%",
    },
    title: {
        paddingTop: 10,
        fontSize: 22,
        fontWeight: 400,
        textAlign: "left",
        lineHeight: 1.1,
        color: "#414141"
    },
    institution: {
        paddingTop: 40,
        textAlign: "left",
        textTransform: "uppercase",
        color: "#878787",
        fontWeight: 500,
        lineHeight: 1.1, 
        fontSize: 13
    },
    timespan: {
        color: theme.palette.primary.main,
        fontSize: 16,
        textAlign: "left",
        fontWeight: 700,
        lineHeight: 1,
        paddingBottom: 6
    }
}));


export interface EducationTimelineCardContent_Props {
    timespan: string,
    title: string,
    institution: string,
}

const EducationTimelineCardContent = (props: EducationTimelineCardContent_Props) => {
    const classes = useStyles(props)();

    return (
        <div className={classes.root}>
            <Typography className={classes.timespan}>{props.timespan}</Typography>
            <Typography className={classes.title}>{props.title}</Typography>
            <Typography className={classes.institution}>{props.institution}</Typography>
        </div>
    );
}

export default EducationTimelineCardContent;