import React from "react";
import { SvgIcon, makeStyles, createStyles, Theme } from "@material-ui/core";
import { LogoProps } from "./LogoProps";



const useStyles = (props: LogoProps) => makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: props.fontSize,
        height: "auto"
    }
}));    

const LogoHiEfficiency = (props: LogoProps) => {
    const classes = useStyles(props)();

    return (
        <SvgIcon className={classes.root} x="0px" y="0px" width="224.997px" height="85px" viewBox="0 0 224.997 85" enable-background="new 0 0 224.997 85">
		    <path fill="#525352" d="M1.227,61.053L0,57.263c2.655-0.858,6.96-1.961,12.412-3.354c14.516-3.712,36.451-9.319,46.152-15.518
			    c9.47-6.052,14.696-17.639,18.154-25.306c1.355-3.005,2.425-5.378,3.498-6.962l3.3,2.233c-0.881,1.302-1.94,3.648-3.166,6.366
			    c-3.652,8.098-9.171,20.336-19.641,27.025c-10.24,6.543-32.548,12.246-47.31,16.02C8.228,59.092,3.762,60.233,1.227,61.053z"/>
		    <path fill="#AEADAE" d="M7.358,69.965l-1.226-3.792c2.654-0.858,6.959-1.958,12.411-3.354c14.516-3.71,36.452-9.318,46.153-15.518
			    c9.471-6.049,14.697-17.638,18.154-25.305c1.355-3.005,2.425-5.376,3.499-6.963l3.299,2.234c-0.881,1.303-1.939,3.648-3.166,6.368
			    c-3.652,8.098-9.172,20.334-19.641,27.024c-10.24,6.542-32.55,12.246-47.312,16.021C14.359,68.003,9.895,69.145,7.358,69.965z"/>
		    <path fill="#454545" d="M14.25,78.876l-1.226-3.792c2.654-0.856,6.96-1.959,12.411-3.352c14.516-3.712,36.452-9.319,46.153-15.518
			    c9.471-6.052,14.696-17.639,18.154-25.306c1.355-3.005,2.425-5.378,3.498-6.964l3.3,2.235c-0.881,1.302-1.94,3.648-3.166,6.366
			    c-3.652,8.098-9.171,20.336-19.641,27.025c-10.24,6.541-32.549,12.246-47.311,16.02C21.251,76.915,16.785,78.057,14.25,78.876z"/>
		    <path d="M88.593,75.53h-2.756v-5.628h-5.165v5.628h-2.765V62.49h2.765v5.11h5.165v-5.11h2.756V75.53z"/>
		    <path d="M93.711,75.53V62.49h2.765v13.04H93.711z"/>
		    <path d="M115.667,75.53h-7.51V62.49h7.51v2.266h-4.745v2.863h4.415v2.265h-4.415v3.363h4.745V75.53z"/>
		    <path d="M122.935,75.53h-2.72V62.49h7.475v2.266h-4.755v3.362h4.425v2.257h-4.425V75.53z"/>
		    <path d="M134.787,75.53h-2.72V62.49h7.475v2.266h-4.755v3.362h4.425v2.257h-4.425V75.53z"/>
		    <path d="M143.919,75.53V62.49h2.765v13.04H143.919z"/>
		    <path d="M157.333,64.604c-1.041,0-1.847,0.392-2.418,1.173c-0.57,0.782-0.855,1.872-0.855,3.27c0,2.907,1.091,4.361,3.273,4.361
			    c0.915,0,2.024-0.229,3.326-0.687v2.319c-1.07,0.445-2.266,0.669-3.585,0.669c-1.897,0-3.349-0.575-4.354-1.727
			    c-1.005-1.15-1.507-2.802-1.507-4.954c0-1.356,0.246-2.544,0.74-3.563c0.493-1.02,1.202-1.802,2.127-2.346
			    c0.925-0.545,2.009-0.816,3.252-0.816c1.266,0,2.538,0.307,3.817,0.919l-0.893,2.247c-0.487-0.231-0.978-0.434-1.472-0.606
			    C158.293,64.69,157.809,64.604,157.333,64.604z"/>
		    <path d="M165.26,75.53V62.49h2.765v13.04H165.26z"/>
		    <path d="M180.645,75.53h-7.51V62.49h7.51v2.266h-4.745v2.863h4.415v2.265h-4.415v3.363h4.745V75.53z"/>
		    <path d="M196.761,75.53h-3.515l-5.673-9.865h-0.08c0.113,1.742,0.17,2.985,0.17,3.729v6.137h-2.471V62.49h3.487l5.664,9.767h0.063
			    c-0.09-1.694-0.134-2.893-0.134-3.595V62.49h2.488V75.53z"/>
		    <path d="M207.408,64.604c-1.041,0-1.847,0.392-2.418,1.173c-0.57,0.782-0.855,1.872-0.855,3.27c0,2.907,1.091,4.361,3.273,4.361
			    c0.915,0,2.024-0.229,3.326-0.687v2.319c-1.07,0.445-2.266,0.669-3.585,0.669c-1.897,0-3.349-0.575-4.354-1.727
			    c-1.005-1.15-1.507-2.802-1.507-4.954c0-1.356,0.246-2.544,0.74-3.563c0.493-1.02,1.202-1.802,2.127-2.346
			    c0.925-0.545,2.009-0.816,3.252-0.816c1.266,0,2.538,0.307,3.817,0.919l-0.893,2.247c-0.487-0.231-0.978-0.434-1.472-0.606
			    C208.368,64.69,207.884,64.604,207.408,64.604z"/>
		    <path d="M219.394,67.859l2.721-5.369h2.979l-4.326,7.965v5.075h-2.747v-4.986l-4.326-8.054h2.997L219.394,67.859z"/>
        </SvgIcon>
    ); 
}

export default LogoHiEfficiency;