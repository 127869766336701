import React from "react";
import { SvgIcon, makeStyles, createStyles, Theme } from "@material-ui/core";
import { LogoProps } from "./LogoProps";



const useStyles = (props: LogoProps) => makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: props.fontSize,
        height: "auto"
    }
}));    

const LogoGemboxx = (props: LogoProps) => {
    const classes = useStyles(props)();

    return (
        <SvgIcon className={classes.root} x="0px" y="0px" width="224.997px" height="85px" viewBox="0 0 224.997 85" enable-background="new 0 0 224.997 85">
		    <path fill="#525352" d="M158.293,3.244c-10.398,0-19.71,4.621-26.016,11.913c-2.849-0.738-5.84-1.132-8.923-1.132
			    c-3.925,0-7.701,0.647-11.229,1.824C106.056,6.325,95.408,0,83.276,0C71.478,0,61.073,5.978,54.928,15.07
			    c-0.431-0.028-0.862-0.046-1.3-0.046c-10.973,0-19.864,8.893-19.864,19.863s8.892,19.865,19.864,19.865
			    c0.757,0,1.502-0.046,2.235-0.129c6.233,8.349,16.192,13.756,27.413,13.756c3.211,0,6.315-0.453,9.262-1.281
			    C98.65,77.789,110.158,85,123.354,85c11.375,0,21.495-5.357,27.987-13.68c2.247,0.46,4.569,0.703,6.951,0.703
			    c18.996,0,34.39-15.397,34.39-34.39C192.683,18.641,177.289,3.244,158.293,3.244z"/>
			<path fill="#FFFFFF" d="M53.219,47.804c-7.084,0-12.19-5.127-12.19-12.748c0-7.761,5.265-12.717,12.033-12.717
				c3.479,0,6.706,1.266,8.557,3.214l-3.384,4.204c-1.036-1.3-2.822-2.256-4.982-2.256c-3.764,0-6.521,3.213-6.521,7.555
				c0,4.442,2.413,7.621,6.771,7.621c1.253,0,2.413-0.206,3.354-0.648v-4.41h-4.231V32.9h9.151v12.818
				C59.612,46.95,56.635,47.804,53.219,47.804z"/>
		    <path fill="#FFFFFF" d="M69.525,39.126c0.041,2.09,1.145,3.853,2.903,3.853c1.429,0,2.246-0.971,2.738-2.051l4.7,2.34
			    c-1.431,2.773-3.924,4.536-7.686,4.536c-5.026,0-8.87-3.529-8.87-10.044c0-6.196,3.599-10.155,8.709-10.155
			    c5.885,0,8.216,4.212,8.216,9.938v1.584H69.525z M72.06,31.926c-1.717,0-2.534,2.051-2.575,3.528h4.865
			    C74.35,33.436,73.695,31.926,72.06,31.926z"/>
		    <path fill="#FFFFFF" d="M88.116,28.288v2.666h0.08c0.535-1.803,2.382-3.35,5.093-3.35c3.038,0,4.436,1.476,5.01,3.35h0.081
			    c0.78-1.693,2.546-3.35,5.461-3.35c4.969,0,5.79,3.853,5.831,7.993V47.12h-6.364V35.563c0-1.586-0.41-2.989-1.972-2.989
			    c-1.559,0-2.464,1.403-2.464,3.457v11.09H92.55V35.563c0-1.586-0.368-2.989-1.929-2.989c-1.563,0-2.464,1.403-2.464,3.457v11.09
			    h-6.323V28.288H88.116z"/>
		    <path fill="#FFFFFF" d="M111.903,19.897h6.133v10.299h0.118c0.472-1.368,2.085-2.483,4.087-2.483c4.443,0,6.802,4.175,6.802,9.721
			    c0,5.691-2.478,10.264-6.802,10.264c-2.478,0-3.695-1.296-4.324-2.846h-0.078v2.269h-5.936V19.897z M120.355,42.8
			    c1.886,0,2.633-2.305,2.633-5.113c0-2.845-0.747-5.113-2.633-5.113c-1.769,0-2.635,2.269-2.635,5.113
			    C117.721,40.495,118.587,42.8,120.355,42.8z"/>
		    <path fill="#FFFFFF" d="M139.036,47.804c-5.669,0-9.154-4.213-9.154-10.081c0-5.906,3.442-10.118,9.154-10.118
			    c5.71,0,9.109,4.212,9.109,10.118C148.146,43.591,144.746,47.804,139.036,47.804z M139.036,32.573
			    c-1.933,0-2.771,2.269-2.771,5.113c0,2.844,0.839,5.113,2.771,5.113c1.974,0,2.813-2.27,2.813-5.113
			    C141.849,34.842,141.01,32.573,139.036,32.573z"/>
		    <path fill="#FFFFFF" d="M152.303,37.254l-4.562-8.966h6.566l2.317,5.798l2.438-5.798h6.525l-4.718,9.003l5.033,9.829h-6.722
			    l-2.558-5.978l-2.478,5.978h-6.8L152.303,37.254z"/>
		    <circle fill="#FFFFFF" cx="156.626" cy="25.412" r="3.074"/>
		    <path fill="#FFFFFF" d="M170.857,37.254l-4.563-8.966h6.566l2.32,5.798l2.437-5.798h6.524l-4.717,9.003l5.032,9.829h-6.722
			    l-2.555-5.978l-2.478,5.978h-6.8L170.857,37.254z"/>
		    <circle fill="#FFFFFF" cx="175.181" cy="25.412" r="3.073"/>
			<path fill="#AEADAE" d="M150.502,55.327h-1.252l-1.963-6.558h1.406l1.201,4.649h0.029l1.193-4.649h1.385L150.502,55.327z"/>
			<path fill="#AEADAE" d="M155.992,53.438h-2.645c0.037,0.51,0.439,0.871,0.871,0.871c0.381,0,0.645-0.204,0.807-0.481l0.834,0.666
				c-0.344,0.63-0.915,0.982-1.654,0.982c-1.1,0-2.008-0.88-2.008-2.437c0-1.51,0.865-2.473,1.971-2.473
				c1.076,0,1.831,0.936,1.831,2.51C155.999,53.197,155.999,53.326,155.992,53.438z M154.893,52.493c0-0.481-0.241-0.88-0.717-0.88
				c-0.462,0-0.799,0.407-0.828,0.88H154.893z"/>
			<path fill="#AEADAE" d="M158.939,51.956c-0.109-0.037-0.212-0.047-0.307-0.047c-0.645,0-0.857,0.667-0.857,1.047v2.371h-1.201
				v-4.612h1.157v0.667h0.015c0.184-0.464,0.542-0.797,0.988-0.797c0.096,0,0.198,0.009,0.257,0.037L158.939,51.956z"/>
			<path fill="#AEADAE" d="M161.666,51.956c-0.109-0.037-0.212-0.047-0.307-0.047c-0.645,0-0.857,0.667-0.857,1.047v2.371h-1.201
				v-4.612h1.157v0.667h0.015c0.184-0.464,0.542-0.797,0.988-0.797c0.096,0,0.198,0.009,0.257,0.037L161.666,51.956z"/>
			<path fill="#AEADAE" d="M165.508,53.438h-2.645c0.037,0.51,0.439,0.871,0.871,0.871c0.381,0,0.645-0.204,0.807-0.481l0.834,0.666
				c-0.344,0.63-0.915,0.982-1.654,0.982c-1.1,0-2.008-0.88-2.008-2.437c0-1.51,0.865-2.473,1.971-2.473
				c1.076,0,1.831,0.936,1.831,2.51C165.515,53.197,165.515,53.326,165.508,53.438z M164.408,52.493c0-0.481-0.241-0.88-0.717-0.88
				c-0.462,0-0.799,0.407-0.828,0.88H164.408z"/>
			<path fill="#AEADAE" d="M166.704,50.075c-0.388,0-0.688-0.38-0.688-0.833c0-0.444,0.301-0.834,0.688-0.834
				c0.381,0,0.688,0.371,0.688,0.834C167.393,49.714,167.085,50.075,166.704,50.075z M166.104,55.327v-4.612h1.201v4.612H166.104z"
				/>
			<path fill="#AEADAE" d="M172.002,55.327h-1.487l-1.194-2.214h-0.021v2.214h-1.209v-7.002h1.209v4.261h0.021l1.165-1.871h1.458
				l-1.406,2.084L172.002,55.327z"/>
			<path fill="#AEADAE" d="M175.748,53.438h-2.645c0.037,0.51,0.439,0.871,0.871,0.871c0.381,0,0.645-0.204,0.807-0.481l0.834,0.666
				c-0.344,0.63-0.915,0.982-1.654,0.982c-1.1,0-2.008-0.88-2.008-2.437c0-1.51,0.865-2.473,1.971-2.473
				c1.076,0,1.831,0.936,1.831,2.51C175.755,53.197,175.755,53.326,175.748,53.438z M174.648,52.493c0-0.481-0.241-0.88-0.717-0.88
				c-0.462,0-0.799,0.407-0.828,0.88H174.648z"/>
			<path fill="#AEADAE" d="M178.725,55.327v-2.52c0-0.509-0.109-0.973-0.541-0.973c-0.426,0-0.645,0.464-0.645,0.991v2.501h-1.209
				v-4.612h1.164v0.639h0.016c0.168-0.407,0.586-0.769,1.091-0.769c0.981,0,1.333,0.963,1.333,1.89v2.853H178.725z"/>
			<path fill="#AEADAE" d="M183.432,55.327v-0.602h-0.016c-0.234,0.463-0.71,0.731-1.193,0.731c-1.099,0-1.736-1.167-1.736-2.455
				c0-1.287,0.66-2.417,1.722-2.417c0.534,0,0.908,0.314,1.113,0.62h0.015v-2.88h1.201v7.002H183.432z M182.508,51.826
				c-0.57,0-0.863,0.583-0.863,1.176c0,0.603,0.293,1.195,0.863,1.195c0.543,0,0.879-0.584,0.879-1.186
				C183.387,52.428,183.051,51.826,182.508,51.826z"/>
        </SvgIcon>
    ); 
}

export default LogoGemboxx;