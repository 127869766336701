import { Action } from 'redux';
import ReduxState from '../ReduxState';
import OnActiveAnchorIdChanged from '../actions/OnActiveAnchorIdChanged';
import update from "immutability-helper";

const actionIs = <T extends Action>(obj: Action, type: string): obj is T => {
    return obj.type === type;
}

const InitialReducer = (state = new ReduxState(), action: Action): ReduxState =>
{
    if (actionIs<OnActiveAnchorIdChanged>(action, OnActiveAnchorIdChanged.TYPE))
    {
        return update(state, { navigation: { activeAnchorId: { $set: action.anchorId } } });
    }

    return state;
};

export default InitialReducer;