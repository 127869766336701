import React, { useState} from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Grid, Theme, Hidden, Drawer, IconButton } from "@material-ui/core";
import PageBarItem from "./PageBarItem";
import useScrollOffset from "../../hooks/UseScrollOffset";
import { ANCHOR_ABOUT, ANCHOR_RESUME, ANCHOR_PROFICIENCY, ANCHOR_EXPERIENCE, ANCHOR_EDUCATION, ANCHOR_CONTACT } from "../pages/home/HomePage";
import Redux, { Action } from "redux";
import OnActiveAnchorIdChanged from "../../state/redux/actions/OnActiveAnchorIdChanged";
import { connect } from "react-redux";
import { SCROLLABLE_ANCHOR_CLASS_NAME } from "../generic/scroll_anchor/WithScrollableAnchor";
import ReduxState from "../../state/redux/ReduxState";
import PageDrawer from "./PageDrawer";
import MenuIcon from '@material-ui/icons/Menu';
import AssignmentIcon from '@material-ui/icons/Assignment';
import StarIcon from '@material-ui/icons/StarBorder';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

import InfoIcon from '@material-ui/icons/Info';
import TimelineIcon from '@material-ui/icons/Timeline';
import EducationIcon from "../../resources/icons/EducationIcon";

const getVerticalPadding = (side: "left" | "right", props: Props) => {
    if (side === "left") {
        return props.windowIsScrolledDown ? 30 : 50;
    }
    else {
        return props.windowIsScrolledDown ? 18 : 22;
    }
}

const getHorizontalPadding = (props: Props) => {
    return props.windowIsScrolledDown ? 15 : 30;
}

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%"
    },
    bar_root: {
       
        width: "100%",
        top: 0,
        zIndex: 1000,
        left: 0,

        backgroundColor: props.windowIsScrolledDown ? "white" : "transparent",
        position: props.windowIsScrolledDown ? "fixed": "relative",
        boxShadow: props.windowIsScrolledDown ? "0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23)" : "none",
        transition: "box-shadow 300ms ease-out, background-color 300ms ease-out",
    },
    bar_root_wrapper: {
        maxWidth: 1550,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        marginLeft: "auto",
        marginRight: "auto",
    },
    items: {
        flex: 1,
        justifyContent: "flex-end",
        display: "flex",
        flexDirection: "row",
        padding: getHorizontalPadding(props) + "px " + getVerticalPadding("right", props) + "px " + getHorizontalPadding(props) + "px " + getVerticalPadding("left", props) + "px",
        transition: "padding 300ms ease-out",
    },
    text: {
        color: props.windowIsScrolledDown ? "#444444" : "rgba(255, 255, 255, 0.5)",
        transition: "color 300ms ease-out",
    },
    primary: {
        "&:hover": {
            cursor: "pointer"
        },
        padding: 6,
        alignSelf: "center",
        paddingLeft: getHorizontalPadding(props),
        transition: "padding 300ms ease-out"
    },
    T: {
        fontFamily: "Fredoka One",
        fontSize: 30,
        color: theme.palette.primary.main
    },
    S: {
        fontFamily: "Fredoka One",
        fontSize: 30, 
        color: "#919da2"
    },
    menuButton: {
        border: props.windowIsScrolledDown ? "none" : "1px solid rgba(255, 255, 255, 0.1)",
        borderRadius: 2,
        padding: 6, 
        color: props.windowIsScrolledDown ? "#444444" : "rgba(255, 255, 255, 0.5)"
    }
}));

const mapStateToProps = (state: ReduxState) => ({
    currentlyActiveAnchorId: state.navigation.activeAnchorId
});

const mapDispatchToProps = (dispatch: Redux.Dispatch<Action<any>>) => ({
    setActiveAnchorId : (anchorId: string) => dispatch(new OnActiveAnchorIdChanged(anchorId))
});

interface Props {
    windowIsScrolledDown?: boolean,
    setActiveAnchorId: (anchorId: string) => void,
    currentlyActiveAnchorId?: string
}

const PageBar = (props: Props) => {
    const classes = useStyles(props)(props);
    const getScrollOffset = useScrollOffset("vertical");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const itemClasses = {
        title: classes.text
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    const getAnchorTop = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            return element.offsetTop - 90;
        }
    }

    const onItemClick = (id: string) => {

        setIsDrawerOpen(false);
        const offset = getAnchorTop(id);
        if (offset) {
            window.scrollTo({
                top: offset,
                left: 0,
                behavior: "smooth"
            });
        }
    }

    const findActiveAnchorId = () => {
        const anchors = document.getElementsByClassName(SCROLLABLE_ANCHOR_CLASS_NAME);
        var highestAnchorOnScreen: Element | undefined = undefined;
        for (var i = 0; i < anchors.length; i ++) {
            const anchor = anchors.item(i);
            if (anchor) {
                const rect = anchor.getBoundingClientRect();

                const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

                const isOnScreen = (rect.top - 80 <= windowHeight) && ((rect.top - 80 + rect.height) >= 0);
                if (isOnScreen && (highestAnchorOnScreen === undefined || rect.top < highestAnchorOnScreen.getBoundingClientRect().top)) {
                    highestAnchorOnScreen = anchor;
                }
            }
        }

        if (highestAnchorOnScreen) {
            return highestAnchorOnScreen.id;
        } else {
            return null;
        }
    }

    const setActiveAnchorId = (activeId: string | null) => {
        if (activeId != null && props.currentlyActiveAnchorId !== activeId) {
            props.setActiveAnchorId(activeId);
        }
        
    }
    setActiveAnchorId(findActiveAnchorId())

    const items = [
        { id: ANCHOR_ABOUT, title: "About", icon: InfoIcon },
        { id: ANCHOR_RESUME, title: "Resume", icon: AssignmentIcon },
        { id: ANCHOR_PROFICIENCY, title: "Proficiencies", icon: StarIcon},
        { id: ANCHOR_EXPERIENCE, title: "Work Experience", icon: TimelineIcon},
        { id: ANCHOR_EDUCATION, title: "Education", icon: EducationIcon },
        { id: ANCHOR_CONTACT, title: "Contact", icon: QuestionAnswerIcon },
    ];

    return (
        <div className={classes.root}>

            <Grid container className={classes.bar_root}>
                <div className={classes.bar_root_wrapper}>
                    <div className={classes.primary} onClick={scrollToTop}>
                        <span className={classes.T}>T</span>
                        <span className={classes.S}>S</span>
                    </div>
                    <Hidden smDown implementation="js">
                        <div className={classes.items}>
                                {items.map(item => <PageBarItem {...item} onClick={onItemClick} classes={itemClasses} />)}
                        </div>
                    </Hidden>
                    <Hidden mdUp implementation="js">
                        <div className={classes.items}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                edge="start"
                                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                        </div>
                    </Hidden>
                </div>
            </Grid>
                    

            <Hidden mdUp implementation="js">
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    <PageDrawer items={items} onClick={onItemClick} />
                </Drawer>
            </Hidden>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageBar);