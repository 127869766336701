import React from "react";
import { SvgIcon } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const EducationIcon = (props: SvgIconProps) => {

    return (
        <SvgIcon {...props} className={props.className}  x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48" enable-background="new 0 0 48 48">
            <polygon points="8.733,26.569 8.733,35.275 24,43.591 39.268,35.275 39.268,26.569 24,34.884 " />
            <polygon points="24,4.409 0.009,17.47 24,30.53 47.991,17.47 " />
        </SvgIcon>
    ); 
}

export default EducationIcon;