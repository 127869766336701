import React, { useState, useRef, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Grid, FormControl, InputLabel, FormHelperText, Input } from "@material-ui/core";
import { AbstractValidator } from "@silvester/utility-validation";
import { GridSize } from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => createStyles({
    root: {
        width: "100%",
        padding: 20
    },
    control: {
        width: "100%"
    }
}));

interface Props {
    id: string,
    initialValue?: string,
    label: string,
    validator: AbstractValidator,
    onFieldChange: (id: string, value: string, error: string | null) => void, 
    multiline?: boolean,
    numberOfLines?: number,
    size: Size
}

export interface Size {
    xs?: GridSize,
    sm?: GridSize,
    md?: GridSize,
    lg?: GridSize,
    xl?: GridSize
}

const TextInput = (props: Props) => {
    const classes = useStyles();
    const value = useRef<string>(props.initialValue || "");

    const [error, setError] = useState<string | null>(null);

    const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onValueChange(event.target.value || "", true);
    }

    const onValueChange = (newValue: string, shouldSetError: boolean) => {
        value.current = newValue;

        const error = props.validator.validate(newValue, props.label.toLowerCase());
        props.onFieldChange(props.id, newValue, error);
        if (shouldSetError) {
            setError(error);
        }
    }

    useEffect(() =>
    {
        onValueChange(props.initialValue || "", false);
    })

     
    const hasError = () => {
        return error !== undefined;
    }

    return (
        <Grid item {...props.size} className={classes.root}>
            <FormControl error={hasError()} className={classes.control}>
                <InputLabel htmlFor="component-error">{props.label}</InputLabel>
                <Input
                    id="component-error"
                    multiline={props.multiline}
                    defaultValue={props.initialValue || ""}
                    rows={props.numberOfLines || 1}
                    onChange={onInputChange}
                    aria-describedby="component-error-text"
                />
                {hasError() && <FormHelperText id="component-error-text">{error}</FormHelperText>}
            </FormControl>
        </Grid>
    );
}

export default TextInput;