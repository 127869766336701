import React, { useRef } from "react";
import { Card, makeStyles, Theme, createStyles, Typography, Button} from "@material-ui/core";
import ContactForm, { ContactForm_Data } from "./ContactForm";
import update from "immutability-helper";
import ContactFormApi from "./ContactFormApi";
import { WithSnackbar_Props_Injected, withSnackbar } from "../../generic/feedback/WithSnackbar";
import { withScrollableAnchor, WithScrollableAnchor_Props_Injected} from "../../generic/scroll_anchor/WithScrollableAnchor";

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%"
    },
    title: {
        textAlign: "center",
        marginBottom: 30
    },
    card: {
        padding: 20,
        width: "100%"
    },
    card_title: {
        textAlign: "center",
        fontSize: 24, 
        color: "#3d4451",
        fontWeight: 400,
        paddingBottom: 12,
        marginLeft: "20%",
        marginRight: "20%",
        borderBottom: "1px solid #E7E7E7"
    },
    footer: {
        textAlign: "right",
        paddingRight: 20
    },
    button: {

    }
}));


type Props = WithSnackbar_Props_Injected & WithScrollableAnchor_Props_Injected & {

}
 
const ContactFormCard = (props: Props) => {
    const classes = useStyles(props)();
    const data = useRef<ContactForm_Data>({});
    const errors = useRef<ContactForm_Data>({});

    const onFieldChange = (id: string, value: string | null, error: string | null) => {
        if (error === undefined || error === null) {
            data.current = update(data.current, { [id]: { $set: value } });
        }
        errors.current = update(errors.current, { [id]: { $set: error } });
    }

    const onSubmit = async () => {
        if (hasErrors())
        {
            props.showSnackbar("The data you have entered contains errors.");
            return;
        }

        const api = new ContactFormApi("");
        const response = await api.sendContactForm(data.current);
        if (response.success) {
            props.showSnackbar("The message has been successfully sent! We'll be in contact soon!");
        }
        else {
            props.showSnackbar("Something went wrong while trying to send the message. The server says: '" + response.content + "'.");
        }
    }

    const getErrors = () => {
        return Object
            .keys(errors.current)
            .filter(e => (errors.current as any)[e] !== undefined && (errors.current as any)[e] !== null);
    }

    const hasErrors = () => {
        return getErrors().length > 0;
    }

    return (
        <div  className={classes.root}>
            <Typography variant="h3" className={classes.title}>Contact Me</Typography>
            <Card elevation={3} className={classes.card}>
                <Typography variant="h4" className={classes.card_title}>Feel free to reach out to me!</Typography>
                <ContactForm onFieldChange={onFieldChange} />
                <div className={classes.footer}>
                    <Button size="large" color="primary" className={classes.button} variant="contained" onClick={onSubmit}>Send</Button>
                </div>
            </Card>
        </div>
    );
}

export default withScrollableAnchor(withSnackbar(ContactFormCard));