import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import BackgroundImage from "../../resources/images/header_image.jpg";
import { Grid } from "@material-ui/core";
import PageBar from "./PageBar";
import useScrollOffset from "../../hooks/UseScrollOffset";

const useStyles = () => makeStyles(theme => createStyles({
    root: {
        position: "relative",
        height: "100%",
        width: "100%",
    },
    header: {
        backgroundImage: `url(${BackgroundImage})`,
        width: "100%",
        height: "40vh",
        position: "absolute",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        opacity: 0.75,
        zIndex: 0
    },
    overlay: {
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
    content: {
        zIndex: 100,
        position: "relative",
        width: "100%",
        paddingTop: 200,
        paddingBottom: 50
    },
    grid: {
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        maxWidth: 1080,
        paddingRight: 20,
        paddingLeft: 20
    },
    bar: {
        width: "100%",
        zIndex: 105,
        position: "fixed"
    }
}));

interface Props {
    children: React.ReactNode
}

const PageContainer = (props: Props) =>
{
    const getScrollOffset = useScrollOffset("vertical");
    const classes = useStyles()(props); 

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.overlay}>

                </div>
            </div>
            <div className={classes.bar}>
                <PageBar windowIsScrolledDown={getScrollOffset() >= 1} />
            </div>
            <div className={classes.content}>
                <Grid className={classes.grid} container xs={11} md={10} >
                    {props.children}
                </Grid>
            </div>
        </div>    
    );
}

export default PageContainer;