import AbstractReduxAction from './AbstractReduxAction';

export default class OnActiveAnchorIdChanged extends AbstractReduxAction
{
    public static readonly TYPE = "on_active_anchor_id_changed";
    public readonly anchorId: string;

    constructor(anchorId: string)
    {
        super(OnActiveAnchorIdChanged.TYPE);
        this.anchorId = anchorId;
    }
}