import ReduxNavigationEntity from "./entities/ReduxNavigationEntity";

export default class ReduxState
{
    navigation: ReduxNavigationEntity

    constructor()
    {
        this.navigation = { }
    }
}
