import React from "react";
import { SvgIcon, makeStyles, createStyles, Theme } from "@material-ui/core";
import { LogoProps } from "./LogoProps";



const useStyles = (props: LogoProps) => makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: props.fontSize,
        height: "auto"
    }
}));    

const LogoGemCloud = (props: LogoProps) => {
    const classes = useStyles(props)();

    return (
        <SvgIcon className={classes.root} x="0px" y="0px" width="224.997px" height="85px" viewBox="0 0 224.997 85" enable-background="new 0 0 224.997 85">
		    <path fill="#454545" d="M17.43,63.648c-10.126,0-17.429-6.723-17.429-16.714c0-10.171,7.527-16.669,17.206-16.669
		        c4.972,0,9.588,1.658,12.23,4.212l-4.839,5.511c-1.479-1.702-4.031-2.957-7.124-2.957c-5.377,0-9.319,4.211-9.319,9.903
		        c0,5.823,3.449,9.991,9.677,9.991c1.795,0,3.451-0.269,4.796-0.852v-5.781h-6.049v-6.184h13.084v16.804
		        C26.57,62.526,22.314,63.648,17.43,63.648z"/>
	        <path fill="#454545" d="M57.628,53.699H41.451c0.226,2.464,2.689,4.212,5.332,4.212c2.329,0,3.943-0.985,4.929-2.331l5.108,3.229
		        c-2.105,3.045-5.601,4.749-10.126,4.749c-6.721,0-12.277-4.256-12.277-11.786c0-7.302,5.287-11.963,12.053-11.963
		        c6.587,0,11.203,4.526,11.203,12.144C57.673,52.535,57.673,53.161,57.628,53.699z M50.907,49.129c0-2.33-1.479-4.256-4.392-4.256
		        c-2.823,0-4.885,1.972-5.064,4.256H50.907z"/>
	        <path fill="#AEADAE" d="M134.26,62.839V28.966h7.438v33.873H134.26z"/>
	        <path fill="#AEADAE" d="M158.771,63.558c-6.721,0-12.367-4.66-12.367-11.964s5.646-11.783,12.367-11.783
		        c6.765,0,12.412,4.479,12.412,11.783S165.536,63.558,158.771,63.558z M158.771,45.903c-3.45,0-5.241,2.823-5.241,5.69
		        c0,2.868,1.836,5.781,5.287,5.781c3.448,0,5.285-2.913,5.285-5.781C164.103,48.727,162.221,45.903,158.771,45.903z"/>
	        <path fill="#AEADAE" d="M190.544,62.839v-3.09h-0.091c-1.032,1.972-3.584,3.718-6.721,3.718c-6.004,0-8.11-4.658-8.11-9.14V40.526
		        h7.394v12.098c0,2.509,0.626,4.751,3.315,4.751c2.554,0,3.942-2.242,3.942-4.795V40.526h7.348v22.313H190.544z"/>
	        <path fill="#AEADAE" d="M220.254,62.839v-2.912h-0.091c-1.434,2.242-4.346,3.54-7.304,3.54c-6.721,0-10.619-5.644-10.619-11.873
		        c0-6.229,4.034-11.695,10.53-11.695c3.271,0,5.558,1.523,6.811,3.003h0.09V28.966h7.35v33.873H220.254z M214.608,45.903
		        c-3.495,0-5.287,2.823-5.287,5.69c0,2.912,1.792,5.781,5.287,5.781c3.316,0,5.376-2.823,5.376-5.736
		        C219.984,48.816,217.925,45.903,214.608,45.903z"/>
		    <path fill="#454545" d="M34.841,75.004c-0.335-0.446-0.994-0.778-1.616-0.778c-0.722,0-1.555,0.357-1.555,1.237
			    c0,0.829,0.685,1.072,1.679,1.391c1.132,0.357,2.574,0.867,2.574,2.641c0,1.939-1.479,2.883-3.208,2.883
			    c-1.145,0-2.326-0.446-3.022-1.288l1.119-1.071c0.436,0.612,1.231,0.994,1.928,0.994c0.734,0,1.592-0.369,1.592-1.339
			    c0-0.944-0.92-1.212-2.026-1.569c-1.07-0.344-2.227-0.88-2.227-2.563c0-1.837,1.629-2.653,3.221-2.653
			    c0.883,0,1.891,0.318,2.563,0.969L34.841,75.004z"/>
		    <path fill="#454545" d="M40.254,73.116h1.355l3.793,9.031h-1.79l-0.821-2.066h-3.817l-0.796,2.066h-1.754L40.254,73.116z
			        M42.244,78.703l-1.355-3.674l-1.381,3.674H42.244z"/>
		    <path fill="#454545" d="M46.277,73.116h2.45l2.288,6.416h0.037l2.264-6.416h2.462v9.031h-1.554v-7.259h-0.025l-2.674,7.259h-1.094
			    l-2.637-7.259H47.77v7.259h-1.492V73.116z"/>
		    <path fill="#454545" d="M57.725,73.116h5.833v1.403h-4.266v2.296h4.041v1.353h-4.041v2.551h4.489v1.429h-6.057V73.116z"/>
		    <path fill="#454545" d="M65.314,73.116h2.077l4.092,6.761h0.024v-6.761h1.567v9.031h-1.99l-4.179-6.978h-0.024v6.978h-1.567
			    V73.116z"/>
		    <path fill="#454545" d="M74.124,73.116h1.717l1.703,6.633h0.025l1.939-6.633h1.617l1.902,6.633h0.025l1.74-6.633h1.667
			    l-2.574,9.031h-1.518l-2.064-6.773H80.28l-2.064,6.773h-1.518L74.124,73.116z"/>
		    <path fill="#454545" d="M87.512,73.116h5.833v1.403h-4.266v2.296h4.041v1.353h-4.041v2.551h4.489v1.429h-6.057V73.116z"/>
		    <path fill="#454545" d="M95.102,73.116h3.06c1.654,0,3.246,0.676,3.246,2.589c0,1.302-0.784,2.156-2.015,2.437l2.35,4.006h-1.89
			    l-2.04-3.827h-1.144v3.827h-1.567V73.116z M97.949,77.007c0.884,0,1.891-0.217,1.891-1.314c0-0.981-0.945-1.225-1.753-1.225
			    h-1.418v2.539H97.949z"/>
		    <path fill="#454545" d="M102.928,73.116h1.567v3.903h0.037l3.681-3.903h2.114l-4.116,4.171l4.39,4.86h-2.201l-3.867-4.528h-0.037
			    v4.528h-1.567V73.116z"/>
		    <path fill="#454545" d="M111.5,73.116h1.567v9.031H111.5V73.116z"/>
		    <path fill="#454545" d="M115.009,73.116h2.077l4.092,6.761h0.024v-6.761h1.567v9.031h-1.99l-4.179-6.978h-0.024v6.978h-1.567
			    V73.116z"/>
		    <path fill="#454545" d="M132.425,81.676c-0.884,0.434-1.94,0.701-3.32,0.701c-2.836,0-4.813-1.951-4.813-4.771
			    c0-2.896,2.064-4.72,4.775-4.72c1.394,0,2.637,0.459,3.346,1.135l-1.082,1.199c-0.447-0.51-1.306-0.918-2.251-0.918
			    c-1.853,0-3.06,1.441-3.06,3.304c0,1.926,1.194,3.329,3.134,3.329c0.659,0,1.244-0.102,1.754-0.318V78.32h-1.853v-1.326h3.37
			    V81.676z"/>
		    <path fill="#454545" d="M138.821,75.004c-0.335-0.446-0.994-0.778-1.616-0.778c-0.722,0-1.555,0.357-1.555,1.237
			    c0,0.829,0.685,1.072,1.679,1.391c1.132,0.357,2.574,0.867,2.574,2.641c0,1.939-1.479,2.883-3.208,2.883
			    c-1.145,0-2.326-0.446-3.022-1.288l1.119-1.071c0.436,0.612,1.231,0.994,1.928,0.994c0.734,0,1.592-0.369,1.592-1.339
			    c0-0.944-0.92-1.212-2.026-1.569c-1.07-0.344-2.227-0.88-2.227-2.563c0-1.837,1.629-2.653,3.221-2.653
			    c0.883,0,1.891,0.318,2.563,0.969L138.821,75.004z"/>
		    <path fill="#454545" d="M141.474,73.116h2.873c2.015,0,3.32,0.727,3.32,2.615c0,1.989-1.555,2.679-3.42,2.679h-1.206v3.737h-1.567
			    V73.116z M144.135,77.058c1.021,0,1.953-0.255,1.953-1.326c0-1.072-0.933-1.264-1.903-1.264h-1.144v2.59H144.135z"/>
		    <path fill="#454545" d="M149.063,73.116h1.567v7.603h3.743v1.429h-5.311V73.116z"/>
		    <path fill="#454545" d="M158.182,73.116h1.355l3.793,9.031h-1.79l-0.821-2.066h-3.817l-0.796,2.066h-1.754L158.182,73.116z
			        M160.172,78.703l-1.355-3.674l-1.381,3.674H160.172z"/>
		    <path fill="#454545" d="M165.237,74.52h-2.698v-1.403h6.964v1.403h-2.698v7.628h-1.567V74.52z"/>
		    <path fill="#454545" d="M170.526,73.116h5.684v1.403h-4.116v2.475h3.88v1.352h-3.88v3.802h-1.567V73.116z"/>
		    <path fill="#454545" d="M181.947,72.887c2.748,0,4.75,1.824,4.75,4.72c0,2.819-2.002,4.771-4.75,4.771
			    c-2.711,0-4.714-1.951-4.714-4.771C177.233,74.711,179.236,72.887,181.947,72.887z M181.959,80.936
			    c1.804,0,3.035-1.403,3.035-3.329c0-1.862-1.207-3.304-3.035-3.304c-1.815,0-3.021,1.441-3.021,3.304
			    C178.938,79.532,180.169,80.936,181.959,80.936z"/>
		    <path fill="#454545" d="M188.22,73.116h3.06c1.654,0,3.246,0.676,3.246,2.589c0,1.302-0.784,2.156-2.015,2.437l2.35,4.006h-1.89
			    l-2.04-3.827h-1.144v3.827h-1.567V73.116z M191.067,77.007c0.884,0,1.891-0.217,1.891-1.314c0-0.981-0.945-1.225-1.753-1.225
			    h-1.418v2.539H191.067z"/>
		    <path fill="#454545" d="M196.046,73.116h2.45l2.288,6.416h0.037l2.264-6.416h2.462v9.031h-1.554v-7.259h-0.025l-2.674,7.259H200.2
			    l-2.637-7.259h-0.025v7.259h-1.492V73.116z"/>
	        <circle fill="#525352" cx="111.254" cy="25.706" r="6.385"/>
	        <circle fill="#525352" cx="89.374" cy="36.125" r="5.536"/>
	        <circle fill="#525352" cx="94.854" cy="17.319" r="6.135"/>
	        <circle fill="#525352" cx="74.824" cy="18.946" r="3.13"/>
	        <circle fill="#525352" cx="94.354" cy="2.922" r="3.13"/>
	        <circle fill="#525352" cx="105.871" cy="10.309" r="3.255"/>
	        <circle fill="#525352" cx="83.837" cy="7.554" r="1.501"/>
	        <circle fill="#525352" cx="85.942" cy="23.453" r="2.104"/>
	        <circle fill="#525352" cx="113.007" cy="4.801" r="1.252"/>
	        <line fill="none" stroke="#525352" stroke-miterlimit="10" x1="89.659" y1="36.911" x2="112.318" y2="27.271"/>
	        <line fill="none" stroke="#525352" stroke-miterlimit="10" x1="94.353" y1="16.443" x2="91.976" y2="36.125"/>
	        <line fill="none" stroke="#525352" stroke-miterlimit="10" x1="94.854" y1="17.319" x2="111.755" y2="26.208"/>
	        <path fill="#454545" d="M94.249,40.997c-1.272,1.205-2.984,1.948-4.876,1.948c-1.658,0-3.183-0.574-4.392-1.529
		        c-0.842,0.613-1.549,1.386-2.123,2.291c-1.257-2.42-3.496-3.809-6.723-3.809c-3.36,0-5.645,1.837-6.675,3.718H69.37v-3.09h-7.124
		        v22.313h7.392V50.743c0-2.689,1.347-4.795,3.676-4.795c2.195,0,3.181,1.612,3.181,4.167v12.724h7.35V50.787
		        c0-2.509,1.209-4.839,3.719-4.839c2.597,0,3.181,2.15,3.181,4.704v12.187h7.35V49.04C98.093,46.038,97.035,42.686,94.249,40.997z"
		        />
	        <path fill="#AEADAE" d="M125.966,53.432c-1.344,1.881-3.718,3.226-6.722,3.226c-5.287,0-9.005-3.942-9.005-9.723
		        c0-5.603,3.809-9.724,9.141-9.724c2.42,0,4.973,0.94,6.406,2.912l5.199-5.197c-2.645-3.048-7.438-4.658-11.875-4.658
		        c-0.062,0-0.122,0.004-0.183,0.004c-1.586,2.577-4.424,4.301-7.674,4.301c-1.187,0-2.317-0.236-3.356-0.654
		        c-3.691,2.898-5.991,7.395-5.991,13.017c0,9.947,7.303,16.758,17.07,16.758c5.332,0,9.545-2.017,12.321-5.243L125.966,53.432z"/>
        </SvgIcon>
    ); 
}

export default LogoGemCloud;