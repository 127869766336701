import React from "react";
import { makeStyles, Theme, createStyles, AppBar, Tabs, Tab } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import PlatformScoreCard from "../score_cards/instances/PlatformScoreCard";
import LanguageScoreCard from "../score_cards/instances/LanguageScoreCard";

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
    },
    bar: {
        
    },
    tab: {
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        opacity: 1
    },
    tab_selected: {
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        opacity: 1
    },
    indicator: {
        backgroundColor: "#FFFFFF",
        opacity: 0.55,
        height: 3,
        width: "50% !important"
    }
}));


interface Props {

}

const ProficiencyTabs = (props: Props) => {
    const classes = useStyles(props)();
    const [value, setValue] = React.useState(0);

    const onTabPressed = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    }

    const onTabSwiped = (newIndex: number) => {
        setValue(newIndex);
    }

    return (
        <div>
            <AppBar position="static" color="default" className={classes.bar} elevation={0}>
                <Tabs
                    classes={{indicator: classes.indicator}}
                    value={value}
                    onChange={onTabPressed}
                    variant="fullWidth"
                >
                    <Tab classes={{root: classes.tab, selected: classes.tab_selected}} label="Platforms" />
                    <Tab classes={{ root: classes.tab, selected: classes.tab_selected }} label="Languages" />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis='x'
                index={value}
                onChangeIndex={onTabSwiped}
            >
                <PlatformScoreCard component="div" />
                <LanguageScoreCard component="div" />
            </SwipeableViews>
        </div>
    );
}

export default ProficiencyTabs;