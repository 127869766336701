import React from "react";
import { SvgIcon, makeStyles, createStyles, Theme } from "@material-ui/core";
import { LogoProps } from "./LogoProps";



const useStyles = (props: LogoProps) => makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: props.fontSize,
        height: "auto"
    }
}));    

const LogoInnovum = (props: LogoProps) => {
    const classes = useStyles(props)();

    return (
        <SvgIcon className={classes.root} x="0px" y="0px" width="224.997px" height="85px" viewBox="0 0 224.997 85" enable-background="new 0 0 224.997 85">
		    
        <text transform="matrix(0.9872 0 0 1 110.2446 76.4346)" fill="#454545" font-family="'OpenSans-Extrabold'" font-size="9.8063">Impact &amp; Innovatie</text>
	        <path id="Innovum_1_" fill="#0A0A0A" d="M116.448,41.404c-3.933,0.556-7.439,3.4-9.005,7.302c-0.154,0.384-0.325,0.8-0.379,0.924
		        c-0.534,1.223-0.721,4.982-0.326,6.493c0.326,1.249,0.393,1.431,0.961,2.721c1.118,2.526,3.519,4.879,6.009,5.888
		        c1.129,0.455,1.275,0.505,2.25,0.746l0.945,0.233l1.442-0.026c2.861-0.051,5.459-1.105,7.586-3.078
		        c1.406-1.304,2.797-3.468,3.314-5.153c1.369-4.466,0.461-8.88-2.533-12.324c-0.965-1.109-2.118-1.98-3.559-2.69
		        c-0.414-0.201-0.858-0.423-0.987-0.492C121.208,41.439,118.202,41.156,116.448,41.404 M71.691,41.663
		        c-0.825,0.095-0.938,0.123-1.783,0.449c-3.49,1.346-5.82,4.127-6.386,7.615c-0.159,0.98-0.201,11.841-0.047,12.663
		        c0.631,3.415,5.493,3.469,6.175,0.069c0.085-0.431,0.098-1.28,0.098-6.175c0-6.868-0.019-6.706,0.842-7.672
		        c1.66-1.863,4.309-1.448,5.402,0.845c0.301,0.633,0.317,1.005,0.318,7.079c0,6.679-0.014,6.557,0.943,7.527
		        c1.77,1.795,4.912,0.825,5.334-1.647c0.144-0.847,0.104-11.991-0.048-12.853c-0.567-3.229-3.341-6.486-6.325-7.423
		        c-0.141-0.043-0.357-0.13-0.483-0.195C75.172,41.661,73.033,41.511,71.691,41.663 M94.118,41.596
		        c-0.071,0.014-0.443,0.058-0.829,0.101c-0.386,0.042-0.801,0.111-0.924,0.157c-2.074,0.751-3.37,1.537-4.512,2.737
		        c-0.419,0.439-0.998,1.186-1.181,1.523c-0.059,0.105-0.223,0.38-0.366,0.611c-0.445,0.721-0.952,2.315-1.073,3.39
		        c-0.108,0.959-0.085,11.899,0.024,12.422c0.632,2.968,4.607,3.33,5.971,0.546l0.276-0.567l0.033-6.234l0.032-6.229l0.175-0.445
		        c1.18-2.985,5.33-2.794,6.155,0.281c0.08,0.299,0.102,1.323,0.133,6.358c0.038,5.858,0.042,6.011,0.172,6.426
		        c0.961,3.061,5.009,3.092,6.123,0.048l0.174-0.474v-5.84v-5.84l-0.18-0.895c-0.166-0.831-0.288-1.197-0.793-2.396
		        c-0.842-1.993-2.479-3.687-4.536-4.691c-0.404-0.195-0.806-0.38-0.893-0.407c-0.088-0.026-0.325-0.119-0.529-0.204
		        C97.002,41.734,94.719,41.485,94.118,41.596 M181.429,41.605c-1.403,0.187-1.511,0.22-2.739,0.81
		        c-2.039,0.985-3.487,2.752-4.274,5.215l-0.217,0.679v7.13v7.131l0.172,0.372c1.32,2.853,5.02,2.704,6.063-0.242
		        c0.047-0.132,0.079-2.348,0.104-7.003l0.032-6.808l0.178-0.424c0.462-1.102,1.591-1.63,2.427-1.134
		        c0.626,0.372,0.577-0.267,0.616,7.914c0.041,8.218-0.02,7.546,0.772,8.667c1.304,1.85,4.578,1.188,5.418-1.094l0.139-0.378
		        l0.037-7.229c0.039-8.301-0.02-7.575,0.641-7.925c0.755-0.398,1.884,0.106,2.337,1.044l0.178,0.364l0.032,6.904l0.032,6.905
		        l0.186,0.479c1.066,2.734,5.043,2.617,5.977-0.178l0.144-0.43l0.021-6.356c0.025-8.626,0.013-8.725-1.368-10.84
		        c-0.971-1.496-2.113-2.378-4.193-3.246c-1.089-0.455-3.927-0.434-4.946,0.035c-0.189,0.087-0.405,0.178-0.479,0.202
		        c-0.074,0.023-0.375,0.174-0.668,0.336c-1.099,0.604-1.08,0.604-2.175,0.042c-1.226-0.63-1.438-0.713-2.097-0.823
		        C183.158,41.624,181.815,41.555,181.429,41.605 M56.362,42.068c-1.226,0.436-2.037,1.34-2.219,2.474
		        c-0.094,0.585-0.091,17.386,0.003,17.898c0.66,3.614,6,3.23,6.306-0.451c0.071-0.851,0.07-16.203-0.002-17.036
		        C60.272,42.856,58.223,41.408,56.362,42.068 M132.119,42.068c-1.414,0.519-2.229,1.654-2.229,3.101c0,0.56,0.171,1.377,0.343,1.654
		        c0.136,0.217,0.741,1.57,1.04,2.322c0.156,0.392,0.406,0.958,0.558,1.26c0.153,0.301,0.411,0.883,0.573,1.29
		        c0.162,0.408,0.348,0.859,0.414,1.001c0.066,0.141,0.263,0.564,0.435,0.937c0.174,0.372,0.437,0.981,0.583,1.354
		        c0.145,0.372,0.404,0.968,0.576,1.324c0.314,0.645,0.701,1.535,0.701,1.606c0,0.045,0.291,0.687,0.701,1.553
		        c0.167,0.356,0.412,0.922,0.543,1.261c0.129,0.336,0.359,0.872,0.509,1.192c0.153,0.32,0.378,0.804,0.503,1.072
		        c0.658,1.419,1.634,2.036,3.126,1.979c1.444-0.056,2.357-0.76,2.97-2.289c0.279-0.696,0.285-0.71,0.761-1.729
		        c0.184-0.393,0.462-1.031,0.618-1.421c0.157-0.388,0.423-0.999,0.592-1.354c0.318-0.668,0.523-1.144,0.921-2.123
		        c0.126-0.313,0.372-0.864,0.544-1.227c0.171-0.359,0.475-1.046,0.672-1.523c0.196-0.48,0.519-1.206,0.712-1.614
		        c0.193-0.407,0.403-0.888,0.464-1.064c0.125-0.365,0.734-1.729,0.862-1.933c0.046-0.071,0.214-0.467,0.373-0.874
		        c0.158-0.408,0.409-1.02,0.558-1.355c0.796-1.822-0.123-3.726-2.139-4.428c-1.646-0.578-3.245,0.475-4.136,2.719
		        c-0.408,1.03-0.406,1.022-0.813,1.902c-0.205,0.445-0.477,1.081-0.602,1.415c-0.126,0.334-0.369,0.902-0.537,1.258
		        c-0.331,0.701-0.38,0.813-0.798,1.845c-1.04,2.573-1.233,2.645-2.001,0.735c-0.084-0.21-0.295-0.685-0.47-1.059
		        c-0.341-0.726-0.62-1.385-0.808-1.903c-0.064-0.178-0.304-0.714-0.532-1.192c-0.227-0.48-0.52-1.147-0.648-1.486
		        c-0.128-0.336-0.359-0.874-0.508-1.194c-0.151-0.318-0.408-0.865-0.569-1.211C135.252,42.318,133.575,41.534,132.119,42.068
		         M154.769,42.039c-1.382,0.455-2.2,1.5-2.335,2.979c-0.084,0.93-0.029,11.752,0.063,12.354c0.096,0.641,0.635,2.287,0.967,2.972
		        c1.109,2.259,3.857,4.521,6.082,5.003c0.105,0.021,0.378,0.103,0.604,0.182c0.695,0.233,3.101,0.206,3.982-0.041
		        c4.05-1.151,6.355-3.516,7.465-7.662l0.18-0.677v-6.356v-6.357l-0.234-0.451c-1.197-2.295-3.608-2.781-5.274-1.06
		        c-0.963,0.997-0.956,0.941-0.958,7.824c-0.003,7.213,0.004,7.162-1.015,8.131c-0.886,0.843-1.861,1.118-2.992,0.849
		        c-0.798-0.189-1.715-0.928-2.091-1.675c-0.383-0.758-0.361-0.386-0.396-7.324l-0.035-6.357l-0.279-0.54
		        C157.702,42.3,156.183,41.572,154.769,42.039 M119.315,46.941c2.627,0.841,4.166,3.249,4.17,6.529
		        c0.004,4.957-4.023,8.152-7.595,6.023c-0.526-0.313-1.723-1.543-1.968-2.021c-0.809-1.581-1.01-2.384-1.006-4.002l0.001-1.355
		        l0.231-0.708c0.879-2.742,2.292-4.175,4.573-4.64C118.036,46.704,118.845,46.793,119.315,46.941"/>
		    <path d="M41.617,16.784c0,4.57-3.655,8.272-8.164,8.272c-4.509,0-8.164-3.702-8.164-8.272c0-4.565,3.655-8.27,8.164-8.27
			    C37.962,8.515,41.617,12.219,41.617,16.784z"/>
	        <path fill="#AEADAE" d="M149.119,17.32c-0.413-0.261-0.914-0.599-1.113-0.747c-0.475-0.362-1.272-0.441-1.62-0.165
		        c-1.58,1.265,1.935,5.089,3.782,4.113C151.407,19.867,150.951,18.479,149.119,17.32z"/>
	        <path fill="#AEADAE" d="M73.252,84.947c0.07,0,0.126-0.058,0.126-0.129c0-0.023,0.012-0.067,0.028-0.121
		        c-0.051,0.198,0.012,0.25,0.182,0.25c0.146,0,0.209-0.046,0.209-0.152c0-0.05,0.066-0.146,0.163-0.254
		        c-0.365,0.483,0.383,0.632,1.314,0.15c0.271-0.141,0.542-0.253,0.603-0.253c0.383-0.004,1.962-1.112,2.526-1.769
		        c1.91-2.236,2.056-2.483,2.004-3.397c-0.051-0.922-0.459-1.574-1.496-2.384c-1.378-1.078-1.911-1.274-3.731-1.371
		        c-1.666-0.087-2.913-0.218-4.107-0.427c-0.506-0.09-1.355-0.198-1.887-0.244c-0.55-0.045-1.176-0.158-1.46-0.257
		        c-0.273-0.095-0.894-0.233-1.377-0.307c-0.993-0.148-1.149-0.218-1.985-0.854c-1.415-1.078-3.303-2.088-4.295-2.293
		        c-1.67-0.353-3.928-1.401-4.375-2.036c-0.16-0.229-0.62-0.768-1.023-1.197c-0.854-0.909-0.959-1.102-1.328-2.384
		        c-0.195-0.685-0.405-1.183-0.732-1.741c-0.251-0.429-0.588-1.046-0.749-1.366c-0.329-0.659-0.787-1.188-1.641-1.896
		        c-0.322-0.268-0.891-0.871-1.263-1.338c-0.372-0.469-0.869-1.066-1.106-1.329c-0.588-0.653-0.7-0.938-0.836-2.102
		        c-0.064-0.556-0.18-1.25-0.256-1.549c-0.296-1.153-0.556-2.891-0.624-4.194c-0.062-1.173-0.111-1.497-0.365-2.378
		        c-0.341-1.181-0.366-1.313-0.472-2.505c-0.043-0.49-0.177-1.294-0.298-1.784c-0.553-2.245-0.382-3.793,0.577-5.221
		        c0.188-0.281,0.481-0.839,0.653-1.243c0.172-0.403,0.556-1.109,0.853-1.57c0.538-0.837,0.754-1.377,1.016-2.538
		        c0.271-1.203,1.021-2.418,1.493-2.418c0.116,0,0.426,0.099,0.689,0.222c1.363,0.633,2.117,0.152,3.64-2.305
		        c0.285-0.463,0.637-0.893,0.958-1.172c1.568-1.363,1.768-1.657,1.919-2.843c0.119-0.939,0.2-1.021,1.667-1.678
		        c1.975-0.882,3.368-2.268,4.032-4.006c0.365-0.954,0.443-1.001,1.293-0.825c1.235,0.259,2.042-0.014,2.848-0.962
		        c0.268-0.314,0.715-0.818,0.996-1.123c0.281-0.303,0.675-0.788,0.882-1.074c0.641-0.9,1.151-1.01,2.893-0.615
		        c2.335,0.523,3.444,0.199,4.468-1.309c0.547-0.801,0.806-1.007,1.611-1.277c0.345-0.117,0.854-0.297,1.13-0.4
		        c0.34-0.124,0.911-0.221,1.761-0.296c2.226-0.198,2.948-0.283,3.575-0.422c1.051-0.231,1.589-0.293,2.556-0.295
		        c1.15-0.002,1.945-0.15,3.629-0.675c2.002-0.627,2.099-0.694,2.101-1.468c0.004-1.181,1.38-1.516,2.949-0.714L96,7.521
		        l0.823-0.058c0.989-0.071,1.105-0.033,1.783,0.58c1.606,1.45,2.883,1.878,4.334,1.446c0.409-0.121,1.1-0.251,1.54-0.287
		        c0.987-0.085,1.183-0.135,1.76-0.453c1.268-0.696,1.457-0.771,2.062-0.805l0.593-0.036l0.455,0.39
		        c1.003,0.859,1.467,1.029,3.036,1.118c1.173,0.063,1.285,0.035,1.933-0.499c0.889-0.733,1.452-0.641,3.462,0.568
		        c1.507,0.904,2.954,1.209,3.927,0.823c0.585-0.233,1.871-0.214,2.725,0.037c0.547,0.16,1.12,0.258,1.831,0.305
		        c1.685,0.115,1.763,0.158,3.349,1.903c0.453,0.499,0.742,0.619,1.729,0.723c1.031,0.108,2.751,0.692,3.741,1.271
		        c1.087,0.635,1.309,0.698,2.511,0.721c1.287,0.021,1.367,0.059,2.129,0.98c0.651,0.788,0.942,0.956,1.642,0.944
		        c3.403-0.052,3.785-0.404,2.891-2.642c-0.982-2.467-1.811-3.229-4.02-3.701c-1.382-0.293-1.62-0.426-2.862-1.589
		        c-1.076-1.005-1.084-1.009-4.389-2.054c-4.613-1.456-5.6-1.844-8.142-3.209c-0.944-0.507-1.392-0.567-2.296-0.314
		        c-1.414,0.396-1.754,0.314-3.018-0.72c-1.133-0.931-1.679-1.172-3.352-1.486c-1.362-0.257-2.033-0.397-2.643-0.552
		        c-0.753-0.194-1.878-0.562-1.929-0.631c-0.143-0.198-0.604-0.266-1.786-0.266h-1.274l0.268,0.166
		        c-0.239-0.148-0.42-0.164-1.692-0.16l-1.425,0.008l0.422,0.137c-0.767-0.193-1.531-0.197-1.966-0.01l0.203-0.099l-1.491-0.024
		        l-1.491-0.021l-0.151,0.117c0.068-0.098-0.162-0.113-1.446-0.109c-1.431,0.002-1.523,0.012-1.304,0.131
		        c0.042,0.025,0.091,0.041,0.136,0.062c-0.032-0.012-0.066-0.024-0.098-0.04c-0.282-0.143-0.537-0.156-2.808-0.146
		        c-2.076,0.008-2.401,0.016-2.039,0.091c-0.584-0.063-1.481-0.099-2.388-0.097L89.6,0.037L89.523,0.16
		        c-0.001-0.123-0.278-0.129-1.097-0.129c-0.622,0-1.131,0.016-1.131,0.04c0,0.031,0.016,0.065,0.038,0.095
		        c-0.702-0.229-2.02-0.228-2.465,0.048l0.29-0.183h-0.928c-0.877,0-0.94,0.01-1.122,0.208L83.3,0.031h-0.717
		        c-0.544,0-0.743,0.032-0.824,0.131c-0.384,0.469-1.4,0.69-3.468,0.756c-2.216,0.071-2.732,0.237-3.751,1.207
		        c-0.698,0.662-0.84,0.728-2.453,1.098c-2.184,0.502-2.554,0.643-3.914,1.479c-0.954,0.588-0.86,0.546-2.59,1.159
		        c-1.76,0.628-2.356,0.825-3.144,1.041c-0.369,0.101-0.934,0.345-1.256,0.536c-1.138,0.681-1.492,0.825-2.548,1.035
		        c-0.91,0.182-1.188,0.282-2.236,0.807c-0.659,0.331-1.512,0.719-1.896,0.858c-0.384,0.145-1.057,0.463-1.495,0.711
		        c-0.438,0.249-1.079,0.602-1.425,0.781c-1.274,0.673-1.921,1.593-2.017,2.877c-0.103,1.379-0.744,2.045-3.109,3.236
		        c-2.279,1.147-2.801,1.739-2.967,3.352c-0.069,0.685-0.253,0.922-1.224,1.564c-1.258,0.835-1.495,1.122-2.171,2.634
		        c-0.167,0.372-0.513,1.003-0.768,1.398c-0.255,0.397-0.647,1.049-0.869,1.446c-0.54,0.964-1.36,2.376-1.772,3.057
		        c-1.502,2.467-2.212,5.025-1.909,6.865c0.143,0.872,0.145,0.961,0.012,1.634c-0.172,0.879-0.178,1.753-0.014,2.37
		        c0.19,0.719,0.155,1.3-0.137,2.25c-0.381,1.244-0.375,2.158,0.03,3.65c0.515,1.904,0.577,2.423,0.735,6.115
		        c0.241,5.638,1.143,8.443,3.593,11.188c0.487,0.544,0.722,0.893,0.883,1.296c0.121,0.303,0.322,0.808,0.446,1.122
		        c0.288,0.724,0.645,1.121,1.514,1.691c0.608,0.396,0.748,0.538,1.141,1.153c0.478,0.748,0.96,1.24,2.01,2.06
		        c0.358,0.283,1.103,1.013,1.654,1.63c1.079,1.203,1.521,1.589,3.011,2.619c0.701,0.483,1.286,0.993,2.138,1.862
		        c1.661,1.689,1.854,1.83,3.264,2.332c0.412,0.146,1.167,0.453,1.677,0.679c0.51,0.228,1.266,0.536,1.681,0.687
		        s1.467,0.619,2.336,1.041l1.582,0.766l1.102,0.063c0.605,0.033,1.176,0.089,1.268,0.122c0.375,0.135,0.545,0.252,0.592,0.398
		        c0.039,0.124,0.134,0.158,0.459,0.158h0.408l-0.123-0.356c-0.002-0.004-0.004-0.006-0.005-0.01
		        c0.212,0.613,0.498,0.504,0.456-0.179 M66.49,84.395c0.04,0.655,0.267,0.815,0.266,0.19c0,0.429,0.162,0.48,0.36,0.182
		        c-0.021,0.158,0.194,0.181,0.794,0.181c0.676,0,0.802-0.007,0.883-0.131c-0.01,0.027-0.024,0.056-0.024,0.083
		        c0,0.198,0.461,0.027,0.737-0.253c-0.025,0.037-0.051,0.079-0.089,0.122c-0.104,0.119-0.123,0.164-0.042,0.102
		        c0.082-0.063,0.148-0.077,0.148-0.028c0,0.046,0.442,0.077,1.183,0.085c0.047,0.006,0.089,0.006,0.137,0.002
		        c0.035,0,0.068,0.002,0.104,0.002c0.833,0.002,1.425-0.027,1.425-0.075c0-0.006-0.006-0.008-0.007-0.012
		        c0.111,0.06,0.269,0.104,0.42,0.104c0.317,0,0.341-0.019,0.343-0.233 M100.576,7.375c0.001,0,0.002,0,0.004,0
		        C100.578,7.375,100.577,7.375,100.576,7.375z"/>
	        <path id="path0" fill="#525352" d="M80.546,2.135c-1.309,0.226-3.731,1.034-5.382,1.798C73.513,4.7,71.562,5.325,70.83,5.325
		        c-0.734,0-1.74,0.358-2.238,0.8s-4.645,2.235-9.217,3.99c-4.572,1.751-8.083,3.562-7.805,4.019
		        c0.643,1.052-4.202,5.664-5.949,5.664c-0.868,0-1.167,0.793-0.888,2.354c0.292,1.628,0.048,2.208-0.792,1.881
		        c-1.455-0.567-4.769,4.227-4.593,6.64c0.068,0.94-0.257,1.472-0.722,1.182c-2.209-1.383-4.263,10.896-2.347,14.034
		        c0.52,0.854,0.363,0.983-0.516,0.434c-0.94-0.588-1.091-0.338-0.592,0.978c0.367,0.974,0.918,4.408,1.223,7.635
		        c1.014,10.723,21.206,31.694,27.012,28.058c1.048-0.657,3.9,0.513,3.37,1.383c-0.232,0.38,1.054,0.661,2.86,0.621
		        c2.094-0.043,3.284-0.544,3.286-1.379c0.002-0.72,0.582-1.084,1.292-0.809c0.709,0.276,2.038-0.386,2.953-1.469
		        c1.764-2.087,0.866-3.512-2.337-3.711c-1.741-0.107-4.35-0.588-7.474-1.383c-1.46-0.372-2.645-0.121-3.137,0.666
		        c-0.596,0.952-0.794,0.855-0.806-0.396c-0.014-1.67-3.549-4.251-4.502-3.286c-0.848,0.86-6.099-2.299-5.488-3.3
		        c0.309-0.509-0.129-0.924-0.977-0.924c-0.849,0-1.288-0.418-0.978-0.926c0.659-1.08-0.987-4.229-4.024-7.7
		        c-1.179-1.35-2.144-3.263-2.144-4.252c0-1.204-0.473-1.618-1.428-1.248c-1.647,0.641-1.951-0.872-0.373-1.859
		        c0.689-0.432,0.811-2.04,0.349-4.64c-1.201-6.776-1.11-10.668,0.287-12.355c0.738-0.892,2.052-3.064,2.92-4.823
		        c1.306-2.649,4.039-3.921,3.247-1.516c-0.478,1.452,0.407,0.657,2.585-2.328c1.833-2.513,1.93-2.94,0.55-2.402
		        c-1.274,0.493-1.54,0.279-1.099-0.886c0.322-0.843,0.583-1.704,0.583-1.909c0-0.206,0.646-0.123,1.437,0.184
		        c1.935,0.754,6.187-2.182,6.17-4.258c-0.01-1.009-0.292-1.258-0.715-0.633c-1.047,1.551-3.893,2.388-4.536,1.336
		        c-0.31-0.507-0.036-0.924,0.609-0.924c0.645,0,1.921-0.685,2.835-1.521c1.315-1.207,2.081-1.298,3.669-0.438
		        c2.074,1.126,4.82-0.453,4.82-2.771c0-0.609,1.146-0.775,2.896-0.424c2.087,0.424,3.22,0.143,4.049-1.001
		        c0.632-0.876,2.866-1.848,4.96-2.162c2.095-0.313,4.667-0.704,5.716-0.869c1.047-0.166,2.332-0.342,2.857-0.396
		        c0.523-0.054,0.31-0.56-0.477-1.13c-1.148-0.828-1.008-0.938,0.714-0.558c1.178,0.264,2.142,0.083,2.142-0.397
		        c0-1.454,7.382-0.979,8.914,0.571c1.87,1.896,3.101,1.876,1.545-0.021c-1.632-1.992-0.331-3.076,1.956-1.63
		        c1.016,0.643,3.033,1.117,4.481,1.057l2.631-0.115l-2.857-0.698c-2.403-0.588-2.584-0.819-1.139-1.456
		        c1.084-0.479,2.859-0.137,4.8,0.922c3.107,1.695,6.282,1.735,6.629,0.087C117.534,2.53,90.343,0.459,80.546,2.135 M118.122,6.374
		        c-0.354,0.576-0.212,1.484,0.312,2.015c0.622,0.629,1.153,0.635,1.532,0.016c0.348-0.57,1.905-0.646,3.917-0.187
		        c3.997,0.914,4.104,0.732,0.84-1.434c-1.375-0.914-2.239-1.233-1.923-0.715c0.315,0.521-0.479,0.944-1.77,0.944
		        c-1.292,0-2.114-0.38-1.832-0.844c0.283-0.465,0.302-0.845,0.039-0.845C118.976,5.325,118.473,5.799,118.122,6.374"/>
        </SvgIcon>
    ); 
}

export default LogoInnovum;
