import React from "react";
import {makeStyles, Theme, createStyles, Grid, Typography } from "@material-ui/core";

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%",
        padding: 10
    },
    title: {
        fontWeight: "bold",
        padding: 10,
        paddingLeft: 0,
        paddingRight: 70,
        color: "#555555",
        textTransform: "uppercase",
        fontSize: "14px"
    },
    value: {
        fontSize: "14px",
        color: "#9da0a7"
    },
    grid_item: {
        width: "100%",
        alignSelf: "center"
    }
}));


interface Props {
    title: string,
    value: string
}

const InfoItem = (props: Props) => {
    const classes = useStyles(props)();

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} sm={4} className={classes.grid_item}>
                <Typography variant="h3" className={classes.title}>{props.title}</Typography>
            </Grid>
            <Grid item xs={12} sm={8} className={classes.grid_item}>
                <Typography variant="h3" className={classes.value}>{props.value}</Typography>
            </Grid>
        </Grid>
    );
}

export default InfoItem;