import React from "react";
import { Card, makeStyles, Theme, createStyles, Grid } from "@material-ui/core";
import ScoreItem, { ScoreItem_Props } from "./ScoreItem";

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%",
        padding: 30
    },
    item: {
        [theme.breakpoints.up('sm')]: {
            "&:nth-child(odd)": {
                paddingRight: 20
            },
            "&:nth-child(even)": {
                paddingLeft: 20
            }
        }
    }
}));


interface Props {
    items: ScoreItem_Props[],
    component?: "card" | "div"
}

const ScoreCard = (props: Props) => {
    const classes = useStyles(props)();

    const getComponent = (children: React.ReactNode) => {
        if (props.component == "div") {
            return <div className={classes.root}>{children}</div>
        }
        else {
            return <Card elevation={3} className={classes.root}>{children}</Card>
        }
    }
    const Component = props.component == "div" ? Grid : Card;

    return (
        <Component elevation={3} className={classes.root}>
            <Grid container xs={12}>
                {props.items.map(item => {
                    return (
                        <Grid item xs={12} sm={6} className={classes.item}>
                            <ScoreItem {...item} />
                        </Grid>
                    );
                })}
            </Grid>
        </Component>
    );
}

export default ScoreCard;