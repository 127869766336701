import React from "react";
import { makeStyles, Theme, createStyles, Typography, Chip } from "@material-ui/core";
import { LogoProps } from "../../../../../resources/logos/LogoProps";

export const offset_top = 40;

const useStyles = (props: ExperienceTimelineCardContent_Props) => makeStyles((theme: Theme) => createStyles({
    root: {

    },
    function: {
        marginBottom: 20,
        textTransform: "uppercase",
        color: "#878787",
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: "0.01em"
    },
    description: {
        marginTop: 25,
        fontSize: 15,
        textAlign: "justify"
    },
    timespan: {
        color: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: 700,
        lineHeight: 1,
        textAlign: "center",
        paddingBottom: 6
    },
    chips: {
        marginTop: 30,
        textAlign: "left"
    },
    chip: {
        marginRight: 8,
        backgroundColor: theme.palette.primary.main,
        opacity: 0.7,
        marginBottom: 8,
        color: "white"
    }
}));


export interface ExperienceTimelineCardContent_Props {
    timespan: string,
    function: string,
    description: string,
    chips: Array<string>,
    logo: React.ComponentType<LogoProps>,
}

const ExperienceTimelineCardContent = (props: ExperienceTimelineCardContent_Props) => {
    const classes = useStyles(props)();

    const renderLogo = () => { 
        const Logo = props.logo;  
        return <Logo fontSize="225px" />
    }

    return (
        <div className={classes.root}>
            <Typography className={classes.timespan}>{props.timespan}</Typography>
            <Typography className={classes.function}>{props.function}</Typography>
            {renderLogo()}
            <Typography className={classes.description}>{props.description}</Typography>
            <div className={classes.chips}>
                {props.chips.map(chip => <Chip size="small" className={classes.chip} label={chip} /> )}
            </div>
        </div>
    );
}

export default ExperienceTimelineCardContent;