import React from "react";
import { SvgIcon, makeStyles, createStyles, Theme } from "@material-ui/core";
import { LogoProps } from "./LogoProps";



const useStyles = (props: LogoProps) => makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: props.fontSize,
        height: "auto"
    }
}));    

const LogoSilvesterConsultancy = (props: LogoProps) => {
    const classes = useStyles(props)();

    return (
        <SvgIcon className={classes.root} x="0px" y="0px" width="224.997px" height="85px" viewBox="0 0 224.997 85" enable-background="new 0 0 224.997 85">
		    <path fill="#454545" d="M101.227,49.918c-0.999,0-1.772,0.375-2.32,1.126s-0.822,1.797-0.822,3.139
			    c0,2.791,1.048,4.188,3.143,4.188c0.88,0,1.944-0.22,3.194-0.659v2.227c-1.027,0.428-2.175,0.642-3.442,0.642
			    c-1.821,0-3.215-0.552-4.179-1.656c-0.965-1.105-1.447-2.69-1.447-4.758c0-1.302,0.236-2.441,0.71-3.421
			    c0.475-0.979,1.155-1.73,2.043-2.253c0.888-0.521,1.928-0.783,3.121-0.783c1.217,0,2.438,0.294,3.666,0.882l-0.856,2.158
			    c-0.469-0.223-0.939-0.416-1.413-0.582C102.148,50,101.684,49.918,101.227,49.918z"/>
		    <path fill="#454545" d="M118.448,54.131c0,2.072-0.514,3.665-1.541,4.778s-2.501,1.67-4.419,1.67s-3.392-0.557-4.419-1.67
			    s-1.541-2.712-1.541-4.795c0-2.084,0.515-3.676,1.545-4.774c1.031-1.1,2.508-1.648,4.433-1.648c1.924,0,3.396,0.554,4.414,1.661
			    C117.939,50.46,118.448,52.053,118.448,54.131z M109.312,54.131c0,1.398,0.265,2.452,0.796,3.16s1.324,1.062,2.381,1.062
			    c2.118,0,3.177-1.407,3.177-4.222c0-2.82-1.053-4.23-3.159-4.23c-1.057,0-1.854,0.355-2.39,1.066
			    C109.579,51.678,109.312,52.732,109.312,54.131z"/>
		    <path fill="#454545" d="M132.15,60.408h-3.374l-5.446-9.472h-0.077c0.108,1.673,0.163,2.866,0.163,3.58v5.892h-2.372V47.888h3.348
			    l5.438,9.378h0.06c-0.085-1.628-0.128-2.777-0.128-3.451v-5.927h2.389V60.408z"/>
		    <path fill="#454545" d="M142.692,56.932c0,1.13-0.406,2.021-1.22,2.672c-0.813,0.65-1.945,0.976-3.396,0.976
			    c-1.336,0-2.518-0.251-3.546-0.753v-2.467c0.845,0.377,1.561,0.643,2.146,0.797s1.12,0.23,1.605,0.23
			    c0.583,0,1.029-0.111,1.341-0.334c0.311-0.223,0.467-0.554,0.467-0.993c0-0.245-0.069-0.464-0.206-0.655
			    c-0.137-0.19-0.338-0.375-0.604-0.552s-0.807-0.46-1.623-0.848c-0.765-0.36-1.339-0.705-1.722-1.037
			    c-0.382-0.331-0.688-0.716-0.916-1.155c-0.229-0.44-0.343-0.954-0.343-1.542c0-1.107,0.376-1.979,1.127-2.612
			    c0.75-0.633,1.788-0.95,3.112-0.95c0.651,0,1.271,0.077,1.863,0.231c0.591,0.154,1.208,0.371,1.854,0.65l-0.856,2.064
			    c-0.668-0.274-1.22-0.466-1.657-0.574c-0.437-0.108-0.866-0.162-1.288-0.162c-0.503,0-0.889,0.116-1.156,0.351
			    c-0.269,0.234-0.402,0.54-0.402,0.916c0,0.234,0.054,0.438,0.162,0.612c0.108,0.175,0.281,0.343,0.519,0.506
			    c0.236,0.162,0.798,0.455,1.683,0.878c1.17,0.56,1.973,1.12,2.406,1.683S142.692,56.115,142.692,56.932z"/>
		    <path fill="#454545" d="M155.153,47.888v8.102c0,0.925-0.207,1.735-0.621,2.433c-0.414,0.696-1.012,1.229-1.794,1.601
			    s-1.707,0.557-2.774,0.557c-1.61,0-2.86-0.412-3.751-1.237s-1.336-1.954-1.336-3.387v-8.067h2.646v7.665
			    c0,0.965,0.193,1.673,0.582,2.124c0.388,0.45,1.03,0.676,1.927,0.676c0.868,0,1.497-0.227,1.889-0.681
			    c0.391-0.454,0.586-1.166,0.586-2.137v-7.647H155.153z"/>
		    <path fill="#454545" d="M158.22,60.408V47.888h2.654v10.328h5.078v2.192H158.22z"/>
		    <path fill="#454545" d="M172.957,60.408h-2.654V50.098h-3.399v-2.21h9.454v2.21h-3.4V60.408z"/>
		    <path fill="#454545" d="M185.948,60.408l-0.907-2.98h-4.564l-0.908,2.98h-2.86l4.419-12.571h3.246l4.436,12.571H185.948z
			     M184.407,55.201c-0.84-2.7-1.313-4.228-1.418-4.581c-0.105-0.354-0.181-0.634-0.227-0.84c-0.188,0.731-0.728,2.538-1.618,5.421
			    H184.407z"/>
		    <path fill="#454545" d="M201.491,60.408h-3.374l-5.446-9.472h-0.077c0.108,1.673,0.163,2.866,0.163,3.58v5.892h-2.372V47.888
			    h3.348l5.438,9.378h0.06c-0.085-1.628-0.128-2.777-0.128-3.451v-5.927h2.389V60.408z"/>
		    <path fill="#454545" d="M209.961,49.918c-0.999,0-1.772,0.375-2.32,1.126s-0.822,1.797-0.822,3.139
			    c0,2.791,1.048,4.188,3.143,4.188c0.88,0,1.944-0.22,3.194-0.659v2.227c-1.027,0.428-2.175,0.642-3.442,0.642
			    c-1.821,0-3.215-0.552-4.179-1.656c-0.965-1.105-1.447-2.69-1.447-4.758c0-1.302,0.236-2.441,0.71-3.421
			    c0.475-0.979,1.155-1.73,2.043-2.253c0.888-0.521,1.928-0.783,3.121-0.783c1.217,0,2.438,0.294,3.666,0.882l-0.856,2.158
			    c-0.469-0.223-0.939-0.416-1.413-0.582C210.883,50,210.418,49.918,209.961,49.918z"/>
		    <path fill="#454545" d="M219.716,53.043l2.611-5.155h2.86l-4.153,7.647v4.873h-2.638v-4.787l-4.153-7.733h2.877L219.716,53.043z"
			    />
		    <path fill="#AEADAE" d="M105.32,24.964v4.42h-0.491c-0.327-0.979-0.891-1.802-1.689-2.469c-0.8-0.667-1.625-1-2.476-1
			    c-0.767,0-1.419,0.219-1.956,0.657c-0.537,0.438-0.806,0.975-0.806,1.608c0,0.308,0.07,0.613,0.21,0.917
			    c0.141,0.303,0.345,0.585,0.611,0.846c0.268,0.261,1.127,0.843,2.581,1.744c1.482,0.902,2.438,1.53,2.868,1.884
			    c0.43,0.355,0.841,0.807,1.234,1.358c0.598,0.85,0.897,1.778,0.897,2.786c0,1.493-0.527,2.678-1.581,3.555
			    c-1.054,0.878-2.33,1.316-3.829,1.316c-0.806,0-1.766-0.145-2.88-0.434c-0.591-0.149-1.003-0.224-1.236-0.224
			    c-0.394,0-0.68,0.182-0.857,0.545h-0.492v-5.048h0.492c0.253,1.305,0.777,2.333,1.573,3.083c0.796,0.751,1.775,1.126,2.937,1.126
			    c0.899,0,1.642-0.224,2.228-0.671c0.585-0.447,0.878-1.016,0.878-1.706c0-0.56-0.188-1.088-0.562-1.587
			    c-0.375-0.499-1.378-1.231-3.011-2.198c-1.501-0.892-2.494-1.58-2.979-2.065s-0.853-1.029-1.1-1.631
			    c-0.248-0.602-0.371-1.239-0.371-1.911c0-1.438,0.493-2.614,1.479-3.528c0.987-0.915,2.257-1.373,3.81-1.373
			    c0.477,0,1.052,0.08,1.726,0.238c0.673,0.149,1.15,0.224,1.431,0.224c0.271,0,0.562-0.154,0.87-0.462H105.32z"/>
		    <path fill="#AEADAE" d="M116.73,25.398v0.49c-0.828,0.187-1.387,0.436-1.676,0.748c-0.288,0.313-0.433,0.958-0.433,1.937v10.741
			    c0,0.755,0.135,1.282,0.405,1.581c0.27,0.299,0.837,0.555,1.703,0.77v0.489h-7.953v-0.489c0.6-0.149,1.047-0.313,1.342-0.49
			    c0.295-0.177,0.501-0.4,0.618-0.671s0.176-0.788,0.176-1.552V28.237c0-0.755-0.139-1.284-0.415-1.587
			    c-0.276-0.303-0.85-0.557-1.721-0.762v-0.49H116.73z"/>
		    <path fill="#AEADAE" d="M126.973,25.398v0.49c-0.899,0.224-1.479,0.48-1.742,0.769c-0.263,0.289-0.394,0.774-0.394,1.455v11.287
			    c0,0.699,0.186,1.175,0.556,1.427c0.369,0.251,1.069,0.377,2.101,0.377c1.076,0,1.913-0.235,2.508-0.706s1.215-1.368,1.861-2.692
			    h0.492l-0.492,4.35h-12.842v-0.489c0.893-0.224,1.467-0.49,1.724-0.797c0.256-0.308,0.384-0.946,0.384-1.916V28.237
			    c0-0.755-0.138-1.284-0.412-1.587c-0.274-0.303-0.84-0.557-1.695-0.762v-0.49H126.973z"/>
		    <path fill="#AEADAE" d="M147.121,25.398v0.49c-0.686,0.14-1.24,0.422-1.663,0.846s-0.84,1.172-1.253,2.245l-5.188,13.608h-0.552
			    l-5.312-13.44c-0.487-1.222-0.923-2.042-1.307-2.461c-0.384-0.42-0.937-0.686-1.658-0.797v-0.49h7.658v0.49
			    c-0.938,0.158-1.407,0.563-1.407,1.214c0,0.298,0.099,0.693,0.296,1.187l3.352,8.511l2.994-8.044
			    c0.179-0.494,0.268-0.914,0.268-1.259c0-0.886-0.603-1.422-1.807-1.609v-0.49H147.121z"/>
		    <path fill="#AEADAE" d="M159.57,31.174h0.548v5.189h-0.548c-0.196-1.427-0.975-2.14-2.332-2.14h-2.965v4.923
			    c0,0.755,0.173,1.287,0.519,1.594s0.939,0.461,1.78,0.461h0.982c1.645,0,2.822-1.04,3.533-3.119h0.491l-0.309,4.07h-12.814v-0.489
			    c0.847-0.178,1.409-0.432,1.688-0.763c0.279-0.331,0.419-0.972,0.419-1.923V28.209c0-0.783-0.144-1.326-0.433-1.629
			    c-0.288-0.303-0.847-0.534-1.675-0.692v-0.49h12.617v3.72h-0.491c-0.281-1.091-0.63-1.825-1.047-2.203
			    c-0.417-0.377-1.084-0.566-2.002-0.566h-3.26v6.923h2.965c0.674,0,1.189-0.16,1.545-0.479
			    C159.14,32.475,159.401,31.935,159.57,31.174z"/>
		    <path fill="#AEADAE" d="M173.171,24.964v4.42h-0.491c-0.327-0.979-0.891-1.802-1.689-2.469c-0.8-0.667-1.625-1-2.476-1
			    c-0.767,0-1.419,0.219-1.956,0.657c-0.537,0.438-0.806,0.975-0.806,1.608c0,0.308,0.07,0.613,0.21,0.917
			    c0.141,0.303,0.345,0.585,0.611,0.846c0.268,0.261,1.127,0.843,2.581,1.744c1.482,0.902,2.438,1.53,2.868,1.884
			    c0.43,0.355,0.841,0.807,1.234,1.358c0.598,0.85,0.897,1.778,0.897,2.786c0,1.493-0.527,2.678-1.581,3.555
			    c-1.054,0.878-2.33,1.316-3.829,1.316c-0.806,0-1.766-0.145-2.88-0.434c-0.591-0.149-1.003-0.224-1.236-0.224
			    c-0.394,0-0.68,0.182-0.857,0.545h-0.492v-5.048h0.492c0.253,1.305,0.777,2.333,1.573,3.083c0.796,0.751,1.775,1.126,2.937,1.126
			    c0.899,0,1.642-0.224,2.228-0.671c0.585-0.447,0.878-1.016,0.878-1.706c0-0.56-0.188-1.088-0.562-1.587
			    c-0.375-0.499-1.378-1.231-3.011-2.198c-1.501-0.892-2.494-1.58-2.979-2.065s-0.853-1.029-1.1-1.631
			    c-0.248-0.602-0.371-1.239-0.371-1.911c0-1.438,0.493-2.614,1.479-3.528c0.987-0.915,2.257-1.373,3.81-1.373
			    c0.477,0,1.052,0.08,1.726,0.238c0.673,0.149,1.15,0.224,1.431,0.224c0.271,0,0.562-0.154,0.87-0.462H173.171z"/>
		    <path fill="#AEADAE" d="M187.391,41.664v0.489h-7.896v-0.489c0.828-0.158,1.387-0.401,1.675-0.728
			    c0.289-0.326,0.433-0.923,0.433-1.79V26.349h-1.682c-1.019,0-1.824,0.219-2.417,0.657c-0.594,0.438-1.035,1.133-1.324,2.084
			    h-0.492l0.169-4.042h0.52c0.365,0.233,0.918,0.35,1.658,0.35h10.861c0.759,0,1.312-0.116,1.658-0.35h0.52l0.169,4.042h-0.492
			    c-0.29-0.951-0.731-1.646-1.324-2.084c-0.594-0.438-1.399-0.657-2.418-0.657h-1.682v12.825c0,0.821,0.146,1.406,0.436,1.755
			    S186.595,41.524,187.391,41.664z"/>
		    <path fill="#AEADAE" d="M203.619,31.174h0.548v5.189h-0.548c-0.196-1.427-0.975-2.14-2.332-2.14h-2.965v4.923
			    c0,0.755,0.173,1.287,0.519,1.594s0.939,0.461,1.78,0.461h0.982c1.645,0,2.822-1.04,3.533-3.119h0.491l-0.309,4.07h-12.814v-0.489
			    c0.847-0.178,1.409-0.432,1.688-0.763c0.279-0.331,0.419-0.972,0.419-1.923V28.209c0-0.783-0.144-1.326-0.433-1.629
			    c-0.288-0.303-0.847-0.534-1.675-0.692v-0.49h12.617v3.72h-0.491c-0.281-1.091-0.63-1.825-1.047-2.203
			    c-0.417-0.377-1.084-0.566-2.002-0.566h-3.26v6.923h2.965c0.674,0,1.189-0.16,1.545-0.479
			    C203.188,32.475,203.45,31.935,203.619,31.174z"/>
		    <path fill="#AEADAE" d="M207.005,42.153v-0.489c1.424-0.112,2.136-0.825,2.136-2.14V28.181c0-0.773-0.147-1.31-0.442-1.608
			    c-0.295-0.298-0.859-0.526-1.693-0.685v-0.49h8.01c2.098,0,3.711,0.369,4.84,1.105c1.129,0.737,1.693,1.772,1.693,3.105
			    c0,0.96-0.294,1.786-0.881,2.476s-1.428,1.193-2.524,1.51v0.056c0.394,0.093,0.82,0.418,1.279,0.973
			    c0.459,0.556,1.124,1.644,1.997,3.266c0.854,1.575,1.476,2.572,1.864,2.992s0.92,0.681,1.595,0.784v0.489h-4.854
			    c-0.636-0.749-1.452-2.106-2.448-4.072c-0.687-1.351-1.196-2.25-1.528-2.697c-0.333-0.448-0.68-0.762-1.04-0.944
			    c-0.36-0.182-1.079-0.272-2.156-0.272v5.146c0,0.737,0.163,1.285,0.491,1.644s0.886,0.594,1.673,0.707v0.489H207.005z
			     M214.003,26.349h-1.152v6.867c0.29,0.019,0.562,0.028,0.814,0.028c1.34,0,2.316-0.287,2.93-0.86
			    c0.613-0.574,0.921-1.48,0.921-2.72C217.516,27.454,216.344,26.349,214.003,26.349z"/>
		    <path fill="#454545" d="M42.499,0c-23.475,0-42.5,19.03-42.5,42.502c0,23.47,19.025,42.498,42.5,42.498
			    c23.473,0,42.5-19.028,42.5-42.498C84.999,19.03,65.972,0,42.499,0z M42.499,82.346c-22.008,0-39.844-17.838-39.844-39.844
			    c0-22.006,17.836-39.846,39.844-39.846c22.006,0,39.844,17.839,39.844,39.846C82.343,64.508,64.505,82.346,42.499,82.346z"/>
			<path fill="#AEADAE" d="M33.72,25.053v8.752h-0.971c-0.646-1.938-1.758-3.568-3.33-4.887c-1.574-1.322-3.203-1.982-4.881-1.982
				c-1.512,0-2.793,0.433-3.854,1.302c-1.059,0.869-1.59,1.93-1.59,3.185c0,0.609,0.139,1.214,0.416,1.813
				c0.277,0.602,0.68,1.159,1.205,1.676c0.525,0.515,2.223,1.668,5.086,3.452c2.922,1.784,4.807,3.03,5.654,3.733
				c0.848,0.701,1.658,1.598,2.434,2.687c1.178,1.684,1.768,3.524,1.768,5.519c0,2.957-1.035,5.305-3.115,7.041
				c-2.08,1.737-4.594,2.605-7.545,2.605c-1.59,0-3.482-0.285-5.68-0.857c-1.164-0.296-1.977-0.445-2.439-0.445
				c-0.773,0-1.342,0.361-1.689,1.082H14.22v-10h0.969c0.5,2.587,1.531,4.622,3.102,6.111c1.574,1.486,3.502,2.226,5.791,2.226
				c1.77,0,3.236-0.442,4.389-1.327c1.152-0.889,1.729-2.014,1.729-3.381c0-1.107-0.367-2.153-1.105-3.145
				c-0.742-0.986-2.715-2.436-5.932-4.35c-2.961-1.766-4.918-3.131-5.875-4.09c-0.957-0.965-1.68-2.039-2.168-3.232
				c-0.488-1.191-0.732-2.455-0.732-3.784c0-2.846,0.971-5.175,2.92-6.985c1.943-1.813,4.443-2.719,7.508-2.719
				c0.939,0,2.07,0.158,3.4,0.473c1.326,0.292,2.266,0.44,2.824,0.44c0.531,0,1.104-0.305,1.711-0.913H33.72z"/>
			<path fill="#AEADAE" d="M40.532,42.973c0-5.096,1.711-9.356,5.137-12.781c3.426-3.426,7.689-5.138,12.783-5.138
				c2.088,0,4.441,0.461,7.063,1.384c1.127,0.407,1.895,0.61,2.299,0.61c0.813,0,1.498-0.573,2.051-1.717h0.914v12.158h-0.914
				c-0.762-3.341-2.121-5.935-4.1-7.782c-1.977-1.847-4.377-2.771-7.201-2.771c-3.086,0-5.537,1.302-7.352,3.905
				c-1.82,2.603-2.57,7.266-2.57,11.661"/>
			<path fill="#AEADAE" d="M48.642,42.502c0,4.396,0.75,8.559,2.57,11.161c1.814,2.606,4.266,3.903,7.352,3.903
				c2.824,0,5.225-0.924,7.201-2.766c1.979-1.847,3.338-4.441,4.1-7.784h0.914v12.162h-0.914c-0.553-1.149-1.238-1.72-2.051-1.72
				c-0.404,0-1.172,0.203-2.299,0.607c-2.621,0.924-4.975,1.383-7.063,1.383c-5.094,0-9.357-1.71-12.783-5.135
				c-3.426-3.428-5.137-7.687-5.137-12.784"/>
        </SvgIcon>
    ); 
}

export default LogoSilvesterConsultancy;