import React, { Component } from 'react';
import { Route } from 'react-router';
import HomePage from './ui/pages/home/HomePage';
import { ThemeProvider as MuiThemeProvider} from '@material-ui/styles';
import { DefaultTheme } from './resources/themes/DefaultTheme';
import { Provider as ReduxStoreProvider } from "react-redux";
import ReduxStoreDefault from './state/redux/stores/ReduxStoreDefault';




export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <ReduxStoreProvider store={ReduxStoreDefault.instance.store}>
                <MuiThemeProvider theme={DefaultTheme}>
                    <Route exact path='/' component={HomePage} />
                </MuiThemeProvider>
            </ReduxStoreProvider>
        );
    }
}
 