import React from "react";
import { makeStyles, Theme, createStyles} from "@material-ui/core";
import ScoreCard from "../ScoreCard";

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%"
    },
    title: {
        textAlign: "center",
        marginBottom: 30
    }
}));


interface Props {
    component?: "card" | "div"
}

const LanguageScoreCard = (props: Props) => {
    const classes = useStyles(props)();

    const getItems = () => {
        return [
            { title: "C#", percentage: 95 },
            { title: "Java", percentage: 85 },
            { title: "JavaScript", percentage: 60 },
            { title: "TypeScript", percentage: 75 },
            { title: "SQL", percentage: 50 },
            { title: "HTML/CSS", percentage: 85 },
            { title: "Swift", percentage: 25 },
        ];
    }

    return (
        <ScoreCard component={props.component} items={getItems()} />
    );
}

export default LanguageScoreCard;