import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Button } from "@material-ui/core";
import { withScrollableAnchor, WithScrollableAnchor_Props_Injected } from "../../generic/scroll_anchor/WithScrollableAnchor";
import Introduction from "../introduction/Introduction";

const padding = 10;

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%",
        paddingTop: 60,
        paddingBottom: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    button: {
        padding: 15,
        marginBottom: 30,
        paddingLeft: 70,
        paddingRight: 70
    },
    anchor: {
        fontWeight: "bold",
        color: "#444444",
        "&:hover": {
            textDecoration: "none"
        }
    }
}));

interface Props extends WithScrollableAnchor_Props_Injected {
}

const DownloadResume = (props: Props) => {
    const classes = useStyles(props)();

    const onClick = () => {
    }

    return (
        <div className={classes.root}> 
            <Button variant="outlined" className={classes.button} onClick={onClick}><a className={classes.anchor} href={process.env.PUBLIC_URL + "/documents/Curriculum Vitae.pdf"} download="Curriculum Vitae.pdf">Download Resume</a></Button>
            <Introduction />
        </div>
    );
}

export default withScrollableAnchor(DownloadResume);