import React from "react";
import { Omit } from "@material-ui/core";

export default abstract class AbstractHigherOrderComponent<ChildProps, InjectedProps, AdditionalProps, State> extends React.Component<AdditionalProps & Omit<ChildProps, keyof InjectedProps>, State>
{
    public abstract getInjectedProps: () => InjectedProps
    public abstract getWrappedComponent: () => React.ComponentType<ChildProps & InjectedProps>
    public abstract getAdditionalChildren: () => React.ReactNode | null;

    public render() {
        const WrappedComponent = this.getWrappedComponent();
        const props: ChildProps & InjectedProps = { ...this.props, ...this.getInjectedProps() } as unknown as ChildProps & InjectedProps

        return (
            <div style={{width: "100%"}}>
                <WrappedComponent {...props} />
                {this.getAdditionalChildren()}
            </div>
        )
    }
}