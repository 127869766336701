import React from "react";
import { SvgIcon, makeStyles, createStyles, Theme } from "@material-ui/core";
import { LogoProps } from "./LogoProps";



const useStyles = (props: LogoProps) => makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: props.fontSize,
        height: "auto"
    }
}));    

const LogoNwo = (props: LogoProps) => {
    const classes = useStyles(props)();

    return (
        <SvgIcon className={classes.root} x="0px" y="0px" width="224.997px" height="85px" viewBox="0 0 224.997 85" enable-background="new 0 0 224.997 85">
		    <polyline fill="#5E5E5E" points="78.916,68.474 78.916,39.005 100.692,68.474 109.148,68.474 109.148,18.5 98.3,18.5 98.3,47.428 
			    76.874,18.5 68,18.5 68,68.474 78.916,68.474 		"/>
		    <polygon fill="#5E5E5E" points="112,18.5 124.493,68.474 134.77,68.474 144.439,39.033 154.21,68.474 164.557,68.474 
			    177.048,18.5 164.886,18.5 158.161,48.064 149.155,18.355 140.002,18.355 130.997,47.791 124.34,18.5 		"/>
		    <path fill="#5E5E5E" d="M191.906,68.474h20.256c6.741-2.736,12.123-8.119,14.858-14.86V33.361
			    c-4.005-9.87-13.68-16.835-24.986-16.835c-14.891,0-26.96,12.071-26.96,26.96C175.074,54.795,182.036,64.468,191.906,68.474z
			     M202.034,27.554c8.76,0,15.862,7.102,15.862,15.859c0,8.762-7.103,15.862-15.862,15.862c-8.761,0-15.862-7.101-15.862-15.862
			    C186.172,34.655,193.273,27.554,202.034,27.554z"/>
	        <path fill="#525352" d="M52.469,85 M52.469,38.139c-2.62,5.715-7.009,10.394-12.656,13.391C25.787,58.972,8.324,53.609,0.885,39.58
		        C0.565,38.978,0.274,38.363,0,37.745V85C52.469,85,52.469,38.139,52.469,38.139z M27.984,6.012
		        c-1.572,0.835-2.82,2.043-3.697,3.457c0.942-0.611,2.063-0.97,3.271-0.97c3.334,0,6.035,2.703,6.035,6.039
		        c0,3.332-2.701,6.035-6.035,6.035c-1.292,0-2.484-0.407-3.466-1.098c1.235,2.159,3.212,3.746,5.603,4.48
		        c2.492,0.764,5.13,0.513,7.432-0.709c2.304-1.221,3.992-3.266,4.755-5.756c0.765-2.49,0.515-5.131-0.708-7.432
		        c-1.75-3.299-5.137-5.183-8.634-5.184C31,4.876,29.439,5.241,27.984,6.012 M21.295,5.483c-7.804,4.14-10.787,13.857-6.646,21.662
		        c2.006,3.78,5.364,6.555,9.454,7.81c4.092,1.256,8.428,0.842,12.209-1.164c3.781-2.004,6.554-5.361,7.809-9.454
		        c1.139-3.707,0.902-7.614-0.644-11.127c0.354,1.896,0.249,3.861-0.335,5.757c-0.947,3.096-3.046,5.633-5.906,7.15
		        c-1.772,0.94-3.707,1.418-5.66,1.418c-1.193,0-2.394-0.178-3.567-0.539c-3.095-0.95-5.635-3.045-7.151-5.905
		        c-3.128-5.901-0.875-13.249,5.026-16.378c0.94-0.5,1.918-0.86,2.91-1.093c-0.005,0-0.009,0-0.016,0
		        C26.247,3.62,23.683,4.216,21.295,5.483 M14.341,5.353C8.483,8.459,4.187,13.662,2.244,19.998
		        c-1.946,6.338-1.307,13.055,1.8,18.913c3.106,5.855,8.309,10.153,14.646,12.098c6.339,1.943,13.055,1.304,18.913-1.802
		        c5.855-3.106,10.152-8.309,12.098-14.646c0.737-2.405,1.103-4.867,1.103-7.311c0-3.255-0.646-6.484-1.924-9.534
		        c0.592,3.055,0.432,6.225-0.507,9.283c-1.507,4.908-4.834,8.935-9.371,11.341c-2.813,1.493-5.886,2.249-8.983,2.249
		        c-1.896,0-3.8-0.282-5.664-0.854c-4.909-1.507-8.937-4.834-11.344-9.368C8.043,21.006,11.621,9.35,20.986,4.383
		        c1.749-0.927,3.6-1.569,5.492-1.922c-0.18-0.005-0.36-0.007-0.54-0.007C21.939,2.454,17.974,3.425,14.341,5.353 M0,0v14.426
		        C2.594,8.533,7.056,3.706,12.831,0.643C13.255,0.417,13.686,0.203,14.119,0H0z M38.524,0c5.646,2.628,10.265,6.992,13.232,12.592
		        c0.253,0.476,0.488,0.958,0.712,1.442V0H38.524z"/>
        </SvgIcon>
    ); 
}

export default LogoNwo;