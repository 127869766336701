import React from "react";
import { makeStyles, Theme, createStyles, Grid, Typography, Hidden, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import LogoDiamondAgile from "../../../resources/logos/LogoDiamondAgile"
import { Omit } from "react-router";
import LogoHiEfficiency from "../../../resources/logos/LogoHiEfficiency";
import LogoInnovum from "../../../resources/logos/LogoInnovum";
import LogoSilvesterConsultancy from "../../../resources/logos/LogoSilvesterConsultancy";
import LogoGemCloud from "../../../resources/logos/LogoGemCloud";
import LogoEquineInstruments from "../../../resources/logos/LogoEquineInstruments";
import LogoNwo from "../../../resources/logos/LogoNwo";
import LogoGemboxx from "../../../resources/logos/LogoGemboxx";
import TimelineCard, { TimelineCard_Props, offset_top } from "./TimelineCard";

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        position: "relative",
        marginTop: 40
    },
    content: {
        height: "100%",
        width: "100%",
        position: "relative"
    },
    right: {
        marginTop: 90
    },
    timeline: {
        height: "100%",
        position: "absolute", 
        left: "50%",
        top: offset_top + 10,
        transform: "translateX(-50%)",
        width: 3,
        backgroundColor: theme.palette.primary.light
    },
    grid: {
        width: "100%"
    },
    title: {
        textAlign: "center",
        marginBottom: 30
    }
}));


interface Props {
    items: Array<React.ReactNode>,
    title: string
}

const Timeline = (props: Props) => {
    const classes = useStyles(props)();

    const theme = useTheme<Theme>();
    const isLargeWindow = useMediaQuery(theme.breakpoints.up("md"));

    const renderLargeCard = (position: "left" | "right" | "middle", index: number) => (content: React.ReactNode) =>
    {
        return <TimelineCard isLargeWindow={isLargeWindow} totalAmount={props.items.length} position={position} index={index} content={content} />
    }

    return (
        <div className={classes.root}>
            <Typography variant="h3" className={classes.title}>{props.title}</Typography>
            <Grid container className={classes.content}>
                <Hidden smDown>
                    <Grid item xs={6}>
                        {props.items.filter((_, index) => index % 2 == 0).map((content, index) => renderLargeCard("left", index * 2)(content))}
                    </Grid>
                    <Grid item xs={6}className={classes.right}>
                        {props.items.filter((_, index) => index % 2 != 0).map((content, index) => renderLargeCard("right", index * 2 + 1)(content))}
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Grid item xs={12}>
                        {props.items.map((content, index) => renderLargeCard("middle", index)(content))}
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    );
}

export default Timeline;