import React from "react";
import AbstractHigherOrderComponent from "../AbstractHigherOrderComponent";
import OnActiveAnchorIdChanged from "../../../state/redux/actions/OnActiveAnchorIdChanged";
import ReduxStoreDefault from "../../../state/redux/stores/ReduxStoreDefault";

export interface WithScrollableAnchor_Props_Injected
{
    setActiveAnchorId: (anchorId: string) => void,
}

interface WithScrollableAnchor_Props_Additional {
    anchorId: string
}

interface State
{
}
export const SCROLLABLE_ANCHOR_CLASS_NAME = "scrollable_anchor";
export const withScrollableAnchor = <ChildProps extends WithScrollableAnchor_Props_Injected> (Component: React.ComponentType<ChildProps>) =>
{
    return class WithScrollableAnchor extends AbstractHigherOrderComponent<
        ChildProps,
        WithScrollableAnchor_Props_Injected, 
        WithScrollableAnchor_Props_Additional,
        State>
    {


        public setActiveAnchorId = (anchorId: string) => {
            ReduxStoreDefault.instance.store.dispatch(new OnActiveAnchorIdChanged(anchorId));
        }

        public getInjectedProps = () =>
        {
            return {
                setActiveAnchorId: this.setActiveAnchorId
            }
        }

        public getWrappedComponent = () =>
        {
            return (
                (props: ChildProps) => 
                    <div>
                        <a className={SCROLLABLE_ANCHOR_CLASS_NAME} id={this.props.anchorId}>
                            <Component {...props} />
                        </a>
                    </div>
            );
        }
        
        public getAdditionalChildren = () =>
        {
            return null;
        }
    }


}
