import React from "react";
import { SvgIcon, makeStyles, createStyles, Theme } from "@material-ui/core";
import { LogoProps } from "./LogoProps";



const useStyles = (props: LogoProps) => makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: props.fontSize,
        height: "auto"
    }
}));    

const LogoEquineInstruments = (props: LogoProps) => {
    const classes = useStyles(props)();

    return (
        <SvgIcon className={classes.root} x="0px" y="0px" width="224.997px" height="85px" viewBox="0 0 224.997 85" enable-background="new 0 0 224.997 85">
		    <defs>
			    <polygon id="SVGID_1_" points="11.568,13.945 11.568,45.569 76.452,45.569 93.611,71.055 81.542,13.945 			"/>
		    </defs>
		    <clipPath id="SVGID_2_">
			    <use href="#SVGID_1_" overflow="visible"/>
		    </clipPath>
		    <path clip-path="url(#SVGID_2_)" fill="#AEADAE" d="M83.086,46.485c-0.326-0.385-0.713-0.729-1.068-1.072
			    c0.834,0.514,1.56,1.197,2.31,1.816c-0.74-0.695-1.561-1.313-2.281-2.025c0.41,0.168,0.795,0.406,1.207,0.574
			    c-0.487-0.498-1.165-0.745-1.763-1.084c-0.564-0.321-1.023-0.901-1.369-1.44c0.578,0.124,1.131,0.356,1.701,0.5
			    c-0.446-0.281-0.94-0.478-1.413-0.716c0.228,0.015,0.521,0.045,0.742,0.118c-0.585-0.191-1.407-0.58-1.636-1.184
			    c-0.147-0.389-0.108-0.845-0.212-1.247c-0.107-0.412-0.3-0.807-0.45-1.203c-0.741-2.01-2.786-3.994-5.021-4.131
			    c-1.194-0.074-2.329,0.33-3.48-0.195c-1.367-0.626-2.636-1.466-4.187-1.566c-0.741-0.048-1.496-0.046-2.238-0.058
			    c-0.093-0.454-0.312-0.293-0.499-0.563c-0.312-0.45,0.391-1.258,0.002-1.634c-0.245-0.24-0.913,0.136-1.174,0.236
			    c-0.771,0.291-1.35,0.38-2.167,0.38c-0.06,0-0.72-0.278-0.74-0.286c0.32,0.136,1.238-1.144,1.372-1.362
			    c0.646-1.073,0.813-2.69-0.403-3.43c-0.412-0.252-0.883-0.367-1.261-0.677c-0.362-0.299-0.63-0.815-1.091-0.638
			    c-0.269,0.105-0.289,0.26-0.653,0.228c-1.332-0.122-2.366-1.264-3.791-0.94c-0.371,0.083-0.443,0.279-0.654-0.142
			    c-0.136-0.27-0.046-0.682-0.468-0.563c0.195-0.645,0.004-1.313-0.474-1.817c-0.27-0.283-0.515-0.418-0.861-0.569
			    c-0.549-0.237-1.074-0.047-1.597,0.156c0,0-0.413,0.206-0.453,0.308c-0.041,0.102-0.298,0.38-0.309,0.392
			    c-0.124,0.129-0.055,0.652-0.095,0.831c-0.096,0.424-0.478,0.491-0.803,0.705c0.164,0.249,0.869-0.041,1.143-0.099l0.124,0.183
			    c0,0-0.061,0.228-0.061,0.288c0,0.063-0.188,0.248-0.188,0.248l-0.039,0.123l0.206,0.08v0.145l0.082,0.144
			    c0,0,0.103,0.187,0.103,0.188c-0.213,0.711,0.496,0.313,0.865,0.463c0.272,0.112,0.482,1.248,0.215,1.403
			    c0.373-0.216,0.255-0.742,0.329-1.092c0.648-0.05,0.736,1.003,0.784,1.424c0.129,1.191-1.026,0.313-0.902,0.072
			    c-0.703-0.006-1.449-0.099-2.02-0.545c0.117-0.033,0.245-0.102,0.368-0.123c-0.445-0.191-0.987-0.129-1.41-0.378
			    c0.277-0.126,0.604-0.025,0.875,0.08c-0.549-0.418-1.255-0.431-1.888-0.627c0.163-0.033,0.334-0.05,0.493-0.093
			    c-0.214-0.093-0.457-0.094-0.684-0.141c0.076-0.034,0.149-0.116,0.224-0.149c-0.311-0.098-0.648-0.008-0.957,0.063
			    c0.131-0.059,0.612-0.338,0.722-0.314c-0.454-0.096-0.916,0.086-1.371,0.058c0.197-0.137,0.451-0.184,0.691-0.177
			    c-0.161-0.027-0.322-0.055-0.47-0.115c0.113-0.107,0.253-0.168,0.405-0.213c-0.648,0.176-1.237,0.461-1.862,0.693
			    c0.298-0.46,0.73-0.682,1.189-0.956c-0.239,0.086-0.483,0.159-0.717,0.257c-0.35,0.148-0.547,0.38-0.857,0.616
			    c0.126-0.675,0.769-0.771,1.319-0.954c-0.213,0.071-0.442,0.104-0.666,0.127c0.046-0.056,0.078-0.142,0.129-0.196
			    c-0.191,0.076-0.381,0.155-0.584,0.188c0.062-0.099,0.116-0.213,0.203-0.297c-0.825,0.665-2.054,0.665-2.98,1.165
			    c-0.375,0.2-1.402,1.089-1.847,0.602c0.021,0.021-0.413,0.175-0.47,0.175c-0.149-0.003-0.227-0.117-0.384-0.148
			    c-0.135-0.026-0.026,0.11-0.026,0.22s-0.275,0.083-0.275,0.083s0.109,0.107,0.22,0.271c-0.016-0.022-0.523,0.247-0.578-0.276
			    c-0.034-0.341,0.249-0.692,0.464-0.913c-0.113,0.064-0.487,0.2-0.545,0.316c0,0,0.11-0.221,0.165-0.304
			    c0.049-0.072,0.104-0.135,0.143-0.209c-0.438,0.145-0.536,0.641-0.858,0.921c-0.296-0.176-0.422-0.546-0.77-0.629
			    c0.169,0.04,0.15,1.152,0.123,1.302c-0.075,0.479-0.286,0.698-0.646,0.999c0.146,0.344,0.362,0.705,0.283,1.091
			    c-0.054,0.272-0.266,0.602-0.34,0.895c-0.23,0.917-0.412,1.849-0.604,2.774c0,0-0.189,0-0.22,0.084
			    c-0.024,0.082-0.024,0.221-0.024,0.303c0,0.08,0.108,0.107,0.108,0.107s-0.192,1.232-0.221,1.452
			    c-0.129,1.045,0.534,2.957,1.905,2.209c0.327-0.179,0.686-0.291,0.779-0.672c0.047-0.189-0.12-0.403-0.059-0.576
			    c0.087-0.24,0.504-0.502,0.675-0.714l0.222-0.275c0.644,0.132,1.18,0.215,1.843,0.192c-0.067,0.101-0.052,0.254-0.111,0.356
			    c0.054,0.033,0.155,0.07,0.219,0.081c0.128-0.583,1.075-0.342,1.546-0.4c1.211-0.152,2.072-0.71,2.967-1.46
			    c0.393,0.446,0.54,0.872,0.811,1.391c0.242,0.459,0.044,0.975,0.168,1.468c-0.663,0.361-1.523,0.233-2.249,0.243
			    c-2.124,0.031-1.899,1.92-1.441,3.415c0.184,0.592,0.229,1.188,0.331,1.795c0.104,0.597,0.31,1.085,0.793,1.469
			    c0.453,0.36,0.981,0.591,1.342,1.057c0.324,0.414,0.511,0.926,1.017,1.163c0.674,0.313,0.674-0.29,0.509-0.763
			    c0.657,0.007,0.524-0.797,0.389-1.188c-0.225-0.644-0.54-1.059-1.29-0.9c0.052-0.012-1.009-0.891-1.068-0.966
			    c-0.341-0.415-0.314-1.024-0.487-1.516c-0.133-0.378-0.286-0.903,0.051-1.222c0.41-0.387,1.514-0.095,2.04-0.095
			    c0.101,0,1.301,0.357,1.34,0.369c3.086,0.921,6.138,1.961,9.358,2.438c0.985,0.146,1.97,0.306,2.966,0.328
			    c1.605,0.034,3.211-0.521,4.811-0.317c1.377,0.176,2.541,0.829,3.435,1.879c0.982,1.15,2.109,1.625,3.389,2.343
			    c0.556,0.315,1.295,0.896,1.52,1.523c0.207,0.575,0.905,0.896,1.288,1.339c0.284,0.335,0.45,0.741,0.65,1.125
			    c0.484,0.925,1.449,1.476,1.84,2.473c0.104,0.268,0.08,0.558,0.118,0.841c0.08,0.591,0.379,1.118,0.541,1.688
			    c0.22,0.793,0.682,1.425,0.997,2.187c0.187,0.445,0.271,0.932,0.557,1.331c0.275,0.383,0.669,0.642,1.002,0.968
			    c0.068,0.067,0.475-1.154,0.491-1.271c0.077-0.532-0.057-1.005-0.386-1.431c-0.398-0.521-0.544-1.174-0.899-1.737
			    c0.237,0.378,0.611,0.6,0.801,1.021c0.143,0.315,0.222,0.631,0.444,0.91c0.083,0.103,0.538,0.513,0.599,0.575
			    c0.091,0.09,0.175,0.164,0.285,0.19c0.209-0.599,0.343-1.355,0.299-1.991c-0.03-0.44-0.204-0.27-0.468-0.538
			    c-0.313-0.32-0.284-0.816-0.448-1.208c-0.188-0.452-0.549-0.79-0.855-1.161c-0.41-0.496-0.713-1.044-0.924-1.652
			    c-0.246-0.727-0.597-1.426-0.908-2.129c-0.299-0.683-0.648-1.329-1.211-1.828c-0.847-0.748-2.213-1.313-2.351-2.579
			    c-0.163-1.473,0.15-3.047,0.325-4.513c0.063-0.519,0.031-1.043-0.165-1.532c-0.279-0.676-1.079-1.111-0.934-1.928
			    c0.311,0.097,0.671,0.327,0.869,0.587c0.231,0.303,0.226,0.698,0.429,1.011c0.027-0.07,0.085-0.14,0.107-0.219
			    c-0.007,1.006,0.047,2.015,0.331,2.984c0.314,1.056,0.949,1.668,1.885,2.214c-0.821-0.479-1.475-1.581-1.771-2.452
			    c-0.396-1.16,0.012-2.376-0.08-3.577c0.333,1.482,0.39,3.073,1.109,4.442c0.638,1.218,1.706,2.124,2.73,3.012
			    c-1.052-1.256-2.696-2.363-2.877-4.127c0.393,0.747,0.657,1.576,1.393,2.073c-0.335-0.532-0.84-0.939-1.08-1.536
			    c-0.27-0.675-0.246-1.456-0.274-2.172c0.059,1.514,1.234,3.194,2.12,4.35c1.004,1.313,2.163,2.498,3.227,3.765
			    c0.519,0.617,1.333,1.478,1.081,2.363c-0.092,0.326-0.313,0.618-0.42,0.944c0.177-0.206,0.356-0.412,0.534-0.616
			    c-0.034,0.41-0.021,0.696-0.492,0.855c-0.292,0.102-0.703,0.044-1.015,0.047c0.422,0.13,0.801,0.187,1.239,0.187
			    c-0.21,0.28-0.475,0.576-0.599,0.906c0.15-0.047,0.28-0.188,0.391-0.313c-0.083,0.204-0.167,0.41-0.245,0.616
			    c0.241-0.285,0.479-0.573,0.721-0.862c-0.083,0.247-0.163,0.494-0.246,0.74c0.074-0.223,0.436-0.549,0.586-0.714
			    c-0.027,0.197-0.068,0.394-0.083,0.593c0.373-0.643,0.705-1.271,1.138-1.886c-0.019,0.17-0.049,0.336-0.074,0.502
			    c0.131-0.92,0.676-1.733,0.402-2.69C83.633,47.222,83.384,46.837,83.086,46.485 M39.629,34.739
			    c-0.021,0.028-0.043,0.05-0.063,0.058c-0.291,0.125-0.804-0.146-1.133-0.095c0.005-0.003,1.268-0.198,1.318-0.206
			    C39.78,34.491,39.703,34.65,39.629,34.739 M39.751,34.005c0.01,0.099-0.066,0.206-0.059,0.308
			    c-0.358,0.041-0.717,0.071-1.074,0.102l-0.518,0.041l0.086-0.143c0.328-0.579,0.749-1.096,1.161-1.617
			    c0.505-0.644,0.822-1.094,1.693-1.254c0.372-0.067,0.666,0.173,1.016,0.349c0.623,0.313,1.104,0.627,1.616,1.103
			    c0.06,0.057-1.502,0.882-1.643,0.935c-0.637,0.232-1.311,0.394-1.986,0.469c0.008-0.115-0.037-0.238-0.024-0.354
			    C39.956,33.938,39.811,33.973,39.751,34.005 M43.173,34.276c-0.354,0.202-0.735,0.357-0.94,0.429
			    c-0.347,0.119-0.79,0.102-1.153,0.131c-0.27,0.021-0.714,0.206-0.948-0.029c-0.22-0.225-0.077-0.306,0.126-0.409
			    c0.454-0.231,1.102-0.229,1.593-0.389c0.7-0.232,1.377-0.526,2.008-0.909C44.226,33.517,43.732,33.956,43.173,34.276"/>
		    <path fill="#454545" d="M91.891,58.832h-6.272l0.004-0.205h0.261c0.723,0,1.309-0.577,1.322-1.293V45.933
			    c-0.018-0.712-0.603-1.284-1.322-1.284h-0.261l-0.003-0.206h6.271l-0.003,0.206h-0.261c-0.719,0-1.303,0.569-1.322,1.279v11.406
			    c0.013,0.716,0.6,1.293,1.322,1.293h0.261L91.891,58.832z"/>
		    <path fill="#454545" d="M108.221,46.5v0.206h-0.35c-0.613,0-1.104,0.437-1.137,1.021v8.981c0,1.434,0.225,2.392,0.225,2.392
			    h-0.186l-9.486-8.316v6.793c0.019,0.599,0.515,1.051,1.138,1.051h0.344v0.205h-5.774v-0.205h0.337
			    c0.623,0,1.133-0.452,1.138-1.051v-9.031c0-1.395-0.226-2.327-0.226-2.327l0.198,0.006l9.462,8.289v-6.756
			    c-0.005-0.6-0.502-1.051-1.125-1.051h-0.35V46.5H108.221z"/>
		    <path fill="#454545" d="M116.683,52.288c1.054,0.646,1.792,1.621,1.699,3.086c-0.127,2.325-1.98,3.726-4.852,3.726
			    c-1.779,0-3.212-0.459-3.807-1.098c-0.317-0.784-0.034-2.353,0.465-3.129h0.161c-0.118,1.86,1.206,3.416,3.411,3.406
			    c1.61-0.013,2.39-0.858,2.39-1.865c0-0.709-0.484-1.183-1.542-1.849l-2.94-1.853c-1.32-0.833-1.981-1.955-1.826-3.362
			    c0.175-1.959,1.622-3.112,3.725-3.112c0.689,0,1.826,0.082,2.684,0.188h1.12l-0.289,2.695h-0.176c0-1.247-0.923-2.063-2.566-2.063
			    c-1.325,0-2.207,0.664-2.258,1.6c-0.059,0.763,0.562,1.158,1.338,1.633L116.683,52.288z"/>
		    <path fill="#454545" d="M131.194,49.426h-0.212v-0.191c0.009-1.172-0.683-1.915-1.889-1.932h-2.384v10.252
			    c0.014,0.61,0.521,1.073,1.139,1.073h0.219v0.205h-5.705v-0.205h0.219c0.623,0,1.122-0.472,1.14-1.092V47.303h-2.385
			    c-1.207,0.018-1.89,0.76-1.89,1.932v0.191h-0.212v-3.261c0.342,0.13,1.397,0.314,2.168,0.314h7.62c0.772,0,1.904-0.185,2.17-0.314
			    L131.194,49.426z"/>
		    <path fill="#454545" d="M138.749,46.5c2.289,0,4.507,0.804,4.507,3.367c0,1.445-0.834,3.028-2.867,3.32
			    c0.818,0.248,1.606,1.117,1.993,1.749c0.174,0.312,0.775,1.33,1.499,2.29c0.807,1.093,1.271,1.396,2.123,1.4v0.205h-1.731
			    c-2.242,0-3.298-0.431-4.279-1.971c-0.455-0.733-1.195-2.112-1.384-2.804c-0.346-0.86-0.856-1.229-1.236-1.26v-0.172h0.528
			    c0.855-0.011,1.799-0.328,2.145-2.056c0.039-0.163,0.087-0.458,0.069-0.859c-0.092-2.027-1.479-2.32-2.194-2.354
			    c-0.532-0.025-0.945,0.001-1.181-0.009v10.188c0.003,0.684,0.573,1.091,1.122,1.091h0.225v0.205h-5.684v-0.205h0.247
			    c0.593,0,1.085-0.46,1.1-1.071v-9.782c-0.011-0.615-0.492-1.068-1.087-1.068h-0.26v-0.206L138.749,46.5z"/>
		    <path fill="#454545" d="M158.922,46.499v0.206h-0.283c-0.509,0-0.93,0.342-0.942,0.849v6.795c0,2.958-1.891,4.751-5.197,4.751
			    c-3.971,0-5.92-1.775-5.92-4.713V47.57c-0.004-0.516-0.429-0.865-0.943-0.865h-0.283v-0.206h5.43v0.206h-0.297
			    c-0.515,0-0.926,0.35-0.93,0.865v6.685c0,2.523,1.24,4.025,3.328,4.025c2.157,0,3.434-1.418,3.449-3.798v-6.916
			    c-0.019-0.514-0.429-0.861-0.942-0.861h-0.283v-0.206H158.922z"/>
		    <path fill="#454545" d="M175.49,57.61c0.15,0.656,0.698,1.017,1.32,1.017h0.291v0.205h-5.744v-0.201h0.289
			    c0.6,0,0.746-0.518,0.714-0.82l-0.808-5.885l-3.292,5.209c0,0-0.731,1.164-0.746,2.088h-0.184l-4.764-7.199l-0.709,5.787
			    c-0.029,0.294,0.152,0.82,0.713,0.82h0.288v0.201h-3.928v-0.205h0.281c0.671,0,1.254-0.395,1.358-1.11l1.603-11.278h0.17
			    l5.832,8.687l5.474-8.685h0.182L175.49,57.61z"/>
		    <path fill="#454545" d="M187.332,58.83h-0.05h-9.659v-0.206h0.247c0.607,0,1.104-0.463,1.118-1.074v-9.773
			    c-0.009-0.615-0.508-1.073-1.118-1.073h-0.247v-0.205h7.264c0.774-0.004,1.818-0.186,2.145-0.315v3.278h-0.214V49.27
			    c0-1.185-0.684-1.928-1.877-1.944h-2.974v4.982h2.348c0.971-0.019,1.576-0.705,1.576-1.625v-0.132h0.214v4.35h-0.214v-0.146
			    c0-0.911-0.595-1.594-1.55-1.624h-2.375v4.875h2.485c1.745-0.017,2.941-1.135,3.482-2.953h0.199L187.332,58.83z"/>
		    <path fill="#454545" d="M204.197,46.5v0.206h-0.35c-0.613,0-1.104,0.437-1.137,1.021v8.981c0,1.434,0.225,2.392,0.225,2.392
			    h-0.186l-9.486-8.316v6.793c0.019,0.599,0.515,1.051,1.138,1.051h0.344v0.205h-5.774v-0.205h0.337
			    c0.623,0,1.133-0.452,1.138-1.051v-9.031c0-1.395-0.226-2.327-0.226-2.327l0.198,0.006l9.462,8.289v-6.756
			    c-0.005-0.6-0.502-1.051-1.125-1.051h-0.35V46.5H204.197z"/>
		    <path fill="#454545" d="M216.787,49.426h-0.212v-0.191c0.009-1.172-0.683-1.915-1.889-1.932h-2.384v10.252
			    c0.014,0.61,0.521,1.073,1.139,1.073h0.219v0.205h-5.705v-0.205h0.219c0.623,0,1.122-0.472,1.14-1.092V47.303h-2.385
			    c-1.207,0.018-1.89,0.76-1.89,1.932v0.191h-0.212v-3.261c0.342,0.13,1.397,0.314,2.168,0.314h7.619
			    c0.773,0,1.905-0.185,2.171-0.314L216.787,49.426z"/>
		    <path fill="#454545" d="M225.025,52.288c1.054,0.646,1.792,1.621,1.699,3.086c-0.127,2.325-1.98,3.726-4.852,3.726
			    c-1.779,0-3.212-0.459-3.807-1.098c-0.317-0.784-0.034-2.353,0.465-3.129h0.161c-0.118,1.86,1.206,3.416,3.411,3.406
			    c1.61-0.013,2.39-0.858,2.39-1.865c0-0.709-0.484-1.183-1.542-1.849l-2.94-1.853c-1.32-0.833-1.981-1.955-1.826-3.362
			    c0.175-1.959,1.622-3.112,3.725-3.112c0.689,0,1.826,0.082,2.684,0.188h1.12l-0.289,2.695h-0.176c0-1.247-0.923-2.063-2.566-2.063
			    c-1.325,0-2.207,0.664-2.258,1.6c-0.059,0.763,0.562,1.158,1.338,1.633L225.025,52.288z"/>
		    <path fill="#525352" d="M8.631,58.832H0.072l0.003-0.205h0.262c0.723,0,1.309-0.576,1.322-1.292V45.826
			    c-0.07-0.662-0.632-1.178-1.315-1.178H0.083l-0.004-0.204h6.033c0.89-0.009,2.078-0.213,2.461-0.374v0.602v0.344v1.65
			    l-0.208-0.003v-0.366c0-0.652-0.511-1.185-1.156-1.224H3v6.354h3.489c0.553-0.011,0.999-0.46,0.999-1.013v-0.303l0.201-0.003
			    v3.253l-0.201-0.003v-0.303c0-0.546-0.435-0.99-0.978-1.012H3v6.158h2.961c2.188,0,3.028-1.126,3.665-2.691h0.207L8.631,58.832z"
			    />
		    <path fill="#525352" d="M35.396,60.436c-0.435,1.109-3.913,4.172-9.127,1.973c-2.194-0.904-5.14-2.815-7.582-3.288
			    c-3.864,0-6.912-2.634-6.912-6.454c0-3.819,3.048-6.454,6.828-6.454s6.828,2.635,6.828,6.454c0,3.238-2.191,5.625-5.162,6.269
			    c1.451,0.432,3.616,1.254,6.124,2.24c2.743,1.09,6.671,1.233,8.935-0.822L35.396,60.436z M18.604,58.505
			    c2.965,0,5.303-2.363,5.303-5.839c0-3.475-2.338-5.839-5.303-5.839s-5.303,2.364-5.303,5.839
			    C13.301,56.142,15.639,58.505,18.604,58.505z"/>
		    <path fill="#525352" d="M40.313,46.5v0.205h-0.307c-0.534,0-0.969,0.369-0.993,0.866v6.918c0,2.871-1.959,4.631-5.146,4.631
			    s-5.163-1.748-5.163-4.6v-6.925c-0.011-0.51-0.45-0.891-0.993-0.891h-0.307V46.5h3.919v0.205h-0.306
			    c-0.543,0-0.982,0.381-0.995,0.891v6.664c0,2.667,1.455,4.302,3.826,4.302c2.604,0,4.203-1.548,4.209-4.073v-6.906
			    c-0.018-0.503-0.455-0.877-0.992-0.877h-0.307V46.5H40.313z"/>
		    <path fill="#525352" d="M42.166,58.832l0.004-0.205h0.275c0.768,0,1.387-0.497,1.402-1.113v-9.695
			    c-0.016-0.617-0.636-1.113-1.403-1.113h-0.276l-0.004-0.206h4.678v0.206h-0.278c-0.751,0-1.363,0.477-1.4,1.077v9.747
			    c0.024,0.609,0.643,1.098,1.401,1.098h0.275l0.004,0.205H42.166z"/>
		    <path fill="#525352" d="M63.328,46.5l-0.003,0.205h-0.343c-0.612,0-1.112,0.455-1.147,1.028v8.765
			    c0,1.324,0.195,2.622,0.195,2.622h-0.196L51.097,48.978v8.576c0.012,0.595,0.521,1.074,1.149,1.074h0.344l0.002,0.205h-3.902
			    l0.003-0.205h0.344c0.628,0,1.138-0.479,1.149-1.074V48.76c0-1.403-0.197-2.567-0.197-2.567l0.222,0.01l10.713,10.053v-8.523
			    c-0.037-0.572-0.536-1.026-1.147-1.026h-0.344l-0.003-0.206L63.328,46.5z"/>
		    <path fill="#525352" d="M73.572,58.833h-8.387l0.004-0.205h0.283c0.666,0,1.167-0.484,1.191-1.092v-9.754
			    c0-0.612-0.521-1.076-1.192-1.076h-0.283L65.185,46.5h6.031c0.83-0.006,1.947-0.183,2.307-0.322v2.326l-0.225-0.003v-0.315
			    c0-0.576-0.407-1.044-1.035-1.057h-4.281v5.229h3.527c0.572-0.012,0.837-0.397,0.837-0.872v-0.261l0.225-0.003v2.887l-0.225-0.002
			    v-0.261c0-0.462-0.25-0.839-0.789-0.871h-3.575v5.229h3.229c1.983-0.026,2.689-0.989,3.276-2.319h0.224L73.572,58.833z"/>
        </SvgIcon>
    ); 
}

export default LogoEquineInstruments;