import React from "react";
import { makeStyles, Theme, createStyles, Card } from "@material-ui/core";

const arrowSize = 15;
const side = 45;
export const offset_top = 40;
const dotSize = 8;
const timelineWidth = 4;

const useStyles = (props: TimelineCard_Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        width: "100%",
        paddingBottom: 30,
    },
    card: {
        marginRight: props.position === "left" && props.isLargeWindow ? 60 : 0,
        marginLeft: props.position === "right" && props.isLargeWindow ? 60 : 0,
        padding: 20,
        width: "100%",
        borderTop: "5px solid " + theme.palette.primary.main
    },
    arrow: {
        "&:after": {
            content: '""',
            position: "absolute",
            top: offset_top,
            right: props.position === "left" ? side : "auto",
            left: props.position === "right" ? side : "auto",
            borderTop: arrowSize + "px solid transparent",
            borderBottom: arrowSize + "px solid transparent",
            borderLeft: arrowSize + "px solid transparent",
            borderRight: arrowSize + "px solid transparent",
            transform: "rotate(45deg)",
            backgroundColor: "white",
        },
        "&:before": {
            content: '""',
            position: "absolute",
            top: offset_top - 3,
            zIndex: -1,
            transform: "rotate(45deg)",
            right: props.position === "left" ? side - 1 : "auto",
            left: props.position === "right" ? side + 1 : "auto",
            borderTop: (arrowSize + 1) + "px solid transparent",
            borderBottom: (arrowSize + 1) + "px solid transparent",
            borderLeft: (arrowSize + 1) + "px solid transparent",
            borderRight: (arrowSize + 1) + "px solid transparent",
            boxShadow: "1px 1px 3px 0px rgba(0,0,0,0.2)",
        }
    },
    dot: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        width: dotSize,
        height: dotSize,
        position: "absolute",
        top: offset_top + 10,
        zIndex: 100,
        right: props.position === "left" ? dotSize / -2 : "auto",
        left: props.position === "right" ? dotSize / -2 : "auto"
    },
    timeline_large_window: {
        backgroundColor: theme.palette.primary.light,
        top: props.index == 0 ? offset_top + 10 : 0,
        right: props.position === "left" ? -2 : "auto",
        left: props.position === "right" ? -2 : "auto",
        position: "absolute",
        height: props.index > 0 && props.index < props.totalAmount - 2 ? "100%" : offset_top + 10,
        width: timelineWidth,
    },
    timeline_small_window: {
        backgroundColor: theme.palette.primary.light,
        width: timelineWidth,
        position: "absolute",
        top: 0,
        height: props.index < props.totalAmount - 1 ? "100%" : "50%",
        right: "50%",
        zIndex: -110,
        translate: "transformX(-50%)"
    }
}));

export interface TimelineCard_Props {
    index: number,
    position: "left" | "right" | "middle",
    totalAmount: number,
    content: React.ReactNode,
    isLargeWindow: boolean
}

const TimelineCard = (props: TimelineCard_Props) => {
    const classes = useStyles(props)();

    return (
        <div className={classes.root}>
            <Card elevation={1} className={classes.card}>
                {props.content}
            </Card>
            {
                props.isLargeWindow && <div>
                    <span className={classes.arrow}></span>
                    <span className={classes.dot}></span>
                    <span className={classes.timeline_large_window}></span>
                </div>
            }
            {
                props.isLargeWindow == false && <div>
                    <span className={classes.timeline_small_window}></span>
                </div>
            }
        </div>
    );
}

export default TimelineCard;