import React from "react";
import { Card, makeStyles, Theme, createStyles, Grid, Typography } from "@material-ui/core";
import ProfilePicture from "../../../resources/images/profielfoto.jpg";
import SpeechBubble from "../SpeechBubble";
import InfoItem from "./InfoItem";
import LinkedInIcon from "../../../resources/icons/LinkedInIcon";
import MailIcon from "../../../resources/icons/MailIcon";
import FooterActionIcon from "./FooterActionIcon";
import { withScrollableAnchor } from "../../generic/scroll_anchor/WithScrollableAnchor";

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column"
    },
    left: {
        padding: 30,
        minHeight: 410,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 0,
            paddingRight: 0,
            marginRight: 30,
            marginLeft: 30,
            paddingBottom: 30,
            borderBottom: "1px solid #DEDEDE",
        }
    },
    image: {
        width: "100%",
        height: "100%",
        display: "flex",
        flex: 1,
        backgroundImage: `url(${ProfilePicture})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      
    },
    right: {
        padding: 30,
        display: "flex",
        flexDirection: "column",
        fontSize: "36px"
    },
    bold: {
        fontWeight: "bold"
    },
    content: {
        color: "#3d4451",
        width: "100%"
    },
    title: {
        fontSize: "28px",
        marginTop: 40
    }, 
    subtitle: {
        marginTop: 6,
        width: "100%",
        paddingBottom: 30,
        marginBottom: 20,
        marginRight: 0,
        borderBottom: "1px solid #DEDEDE",
        fontSize: "18px"
    },
    footer: {
        backgroundColor: theme.palette.primary.main,
        height: 75,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    }
}));


interface Props {

}

const ProfileCard = (props: Props) => {
    const classes = useStyles(props)();

    const onLinkedInClicked = () => {
        window.open("https://www.linkedin.com/in/teun-kooijman-52a806ba/", "_blank")
    }

    const onMailClicked = () => {
        window.open("mailto:teun.kooijman@gmail.com", "_blank")
    }

    return (
        <Card elevation={3} className={classes.root}>
            <div>
                <Grid container xs={12}>
                    <Grid item xs={12} md={4} className={classes.left}>
                        <div className={classes.image}>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8} className={classes.right}>
                        <SpeechBubble text="HELLO"></SpeechBubble>
                        <div className={classes.content}>
                            <Typography className={classes.title} variant="h2">I'm <span className={classes.bold}>Teun Kooijman</span></Typography>
                            <Typography className={classes.subtitle} variant="h3">Experienced Software Engineer</Typography>
                            <InfoItem title="Age" value="25"/>
                            <InfoItem title="Address" value="3452MT - Vleuten, Utrecht, The Netherlands"/>
                            <InfoItem title="Gender" value="Male"/>
                            <InfoItem title="Nationality" value="Dutch"/>
                            <InfoItem title="Freelancer" value="Since 2013" />
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.footer}>
                    <FooterActionIcon onClick={onLinkedInClicked} icon={LinkedInIcon} color="white" fontSize="18px" />
                    <FooterActionIcon onClick={onMailClicked} icon={MailIcon} color="white" fontSize="18px" />
                </Grid>
            </div>
        </Card>
    );
}

export default withScrollableAnchor(ProfileCard);