import { createMuiTheme } from "@material-ui/core/styles";

const font = "Open Sans";

export const DefaultTheme = createMuiTheme({
    palette:
    {
        primary: {
            light: "rgb(255, 197, 191)",
            main: "#e8676b",
            dark: "#bb101d",
            contrastText: "#FFFFFF"
        },
        secondary: {
            light: "#ffc947",
            main: "#ff9800",
            dark: "#c66900",
            contrastText: "#FFFFFF"
        }
    },
    overrides:
    {
        MuiCard:
        {
            root:
            {
                borderRadius: 1
            }
        },
        MuiTabs: {
            indicator: {
                color: "white",
                backgroundColor: "white"
            }
        },
        MuiTypography:
        {
            root: {
                fontFamily: font
            }, 
            h1: {
                fontFamily: font
            },
            h2: {
                fontFamily: font
            },
            h3: {
                fontWeight: 600,
                fontSize: 34,
                lineHeight: 1.2,
                fontFamily: font
            },
            h4: {
                fontFamily: font
            },
            h5: {
                fontFamily: font
            },
            h6: {
                fontFamily: font
            },
            body1: {
                fontFamily: font
            },
            body2: {
                fontFamily: font
            },
            caption: {
                fontFamily: font
            },
            button: {
                fontFamily: font
            },
            subtitle1: {
                fontFamily: font
            },
            subtitle2: {
                fontFamily: font
            },
            overline: {
                fontFamily: font
            }
        }
    }
}); 
 