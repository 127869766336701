import React from "react";
import {  makeStyles, Theme, createStyles} from "@material-ui/core";

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        
    },
    text: {
        fontWeight: "bold",
        fontSize: "14px",
        marginBottom: 0
    },
    bubble: {
        position: "relative",
        background: theme.palette.primary.main,
        flexShrink: 1,
        display: "flex",
        float: "left",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5, 
        color: "#FFFFFF",
        borderRadius: 2,
        width: "inherit",
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            width: 0,
            left: 10,
            height: 0,
            border: "8px solid transparent",
            borderTopColor: theme.palette.primary.main,
            borderBottom: 0,
            borderLegt: 0,
            marginBottom: -8
        }
    },
}));


interface Props {
    text: string
}

const ProfileCard = (props: Props) => {
    const classes = useStyles(props)();

    return (
        <div className={classes.root}>
            <div className={classes.bubble}><p className={classes.text}>{props.text}</p></div>
        </div>
);
}

export default ProfileCard;