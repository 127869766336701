import React from "react";
import { makeStyles, Theme, createStyles, Grid, Typography } from "@material-ui/core";
import LogoDiamondAgile from "../../../../../resources/logos/LogoDiamondAgile"
import { Omit } from "react-router";
import LogoHiEfficiency from "../../../../../resources/logos/LogoHiEfficiency";
import LogoInnovum from "../../../../../resources/logos/LogoInnovum";
import LogoSilvesterConsultancy from "../../../../../resources/logos/LogoSilvesterConsultancy";
import LogoGemCloud from "../../../../../resources/logos/LogoGemCloud";
import LogoEquineInstruments from "../../../../../resources/logos/LogoEquineInstruments";
import LogoNwo from "../../../../../resources/logos/LogoNwo";
import Timeline from "../../Timeline";
import EducationTimelineCardContent, { EducationTimelineCardContent_Props } from "./EducationTimelineCardContent";
import { withScrollableAnchor, WithScrollableAnchor_Props_Injected } from "../../../../generic/scroll_anchor/WithScrollableAnchor";

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%"
    },
}));


interface Props extends WithScrollableAnchor_Props_Injected {

}

const EducationTimeline = (props: Props) => {
    const classes = useStyles(props)();

    const educations: Array<EducationTimelineCardContent_Props> = [
        { timespan:"2016 - 2019", title:"Master of Business Informatics", institution:"Utrecht University" },
        { timespan:"2016", title:"Minor Informatics", institution:"Utrecht University" },
        { timespan: "2013 - 2016", title: "Bachelor of Information Sciences", institution: "Utrecht University" },
        { timespan: "2006 - 2012", title: "Atheneum", institution: "Marnix College" },
    ];

    const items = educations.map(education => <EducationTimelineCardContent {...education} />)

    return (
        <div className={classes.root}>
            <Timeline items={items} title="Education" />
        </div>
    );
}

export default withScrollableAnchor(EducationTimeline);