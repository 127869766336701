import React from "react";
import { Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import AbstractHigherOrderComponent from "../AbstractHigherOrderComponent";

export interface WithSnackbar_Props_Injected
{
    showSnackbar: (message: string) => void
}

export interface WithSnackbar_Props_Additional
{

}

interface State
{
    shouldShowSnackbar: boolean,
    snackbarMessage: string
}

const DEFAULT_SNACKBAR_ERROR_MESSAGE = "Something went wrong, please try again.";

export const withSnackbar = <ChildProps extends WithSnackbar_Props_Injected> (Component: React.ComponentType<ChildProps>) =>
{
    return class WithSnackbar extends AbstractHigherOrderComponent<
        ChildProps,
        WithSnackbar_Props_Injected,
        WithSnackbar_Props_Additional,
        State>
    {
        state: State =
        {
            shouldShowSnackbar: false,
            snackbarMessage: DEFAULT_SNACKBAR_ERROR_MESSAGE
        }

        componentDidMount = async () =>
        {
            
        }

        private showSnackbar = (message: string) =>
        {
            this.setState({ snackbarMessage: message, shouldShowSnackbar: true });
        }

        public getInjectedProps = () =>
        {
            return {
                showSnackbar: this.showSnackbar
            }
        }

        public getWrappedComponent = () =>
        {
            return Component;
        }
        
        public getAdditionalChildren = () =>
        {
            const closeSnackbar = () => this.setState({ shouldShowSnackbar: false });

            return (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.shouldShowSnackbar}
                    autoHideDuration={5000}
                    onClose={closeSnackbar}
                    message={<span>{this.state.snackbarMessage}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={closeSnackbar}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            );
        }
    }
}
