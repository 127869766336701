import React from "react";
import { makeStyles, Theme, createStyles, IconButton } from "@material-ui/core";
import IconProps from "../../../resources/icons/IconProps";

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        paddingLeft: 20,
    }
}));


interface Props extends IconProps {
    icon: React.ComponentType<IconProps>,
    onClick: () => void
}

const InfoItem = (props: Props) => {
    const classes = useStyles(props)();

    const renderIcon = () => {
        const Icon = props.icon;
        return <Icon color={props.color} fontSize={props.fontSize} />;
    }

    return (
        <div className={classes.root}>
            <IconButton onClick={props.onClick}>
                {renderIcon()}
            </IconButton>
        </div>
    );
}

export default InfoItem;