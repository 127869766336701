import { AbstractApi } from "@silvester/utility-auth"
import { ContactForm_Data } from "./ContactForm";
import Cookies from "js-cookie";

export interface ContactFormApi_ResponseType {
    success: boolean,
    content: object
}

export default class ContactFormApi extends AbstractApi
{
    constructor(endpointUrl: string) {
        super(endpointUrl);
    }

    private onSetHeaders = (headers: Record<string, string>) => {
        const csrfToken = Cookies.get("X-CSRF-TOKEN");
        if (csrfToken) {
            headers["RequestVerificationToken"] = csrfToken;
        }
    }

    public sendContactForm = async (data: ContactForm_Data) =>
    {
        const response = await this.sendGetRequest("/api/contact/submit", data as { [key: string]: string }, undefined, undefined, this.onSetHeaders);
        try {
            return await response.clone().json() as ContactFormApi_ResponseType;
        }
        catch (e) {
            return { success: false, content: await response.clone().text() };
        }
    }
}