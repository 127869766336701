import React from "react";
import { SvgIcon, makeStyles, createStyles, Theme } from "@material-ui/core";
import IconProps from "./IconProps";


const useStyles = (props: IconProps) => makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: props.fontSize,
        color: props.color
    }
}));

const LinkedInIcon = (props: IconProps) => {
    const classes = useStyles(props)();

    return (
        <SvgIcon x="0px" y="0px" width="250px" height="250px" viewBox="155.908 155.908 283.465 283.465" enable-background="new 155.908 155.908 283.465 283.465" className={classes.root}>
            <g>
	            <rect x="155.908" y="244.491" width="63.39" height="194.881"/>
	            <path d="M391.964,246.776c-0.674-0.212-1.313-0.443-2.021-0.638c-0.85-0.195-1.701-0.354-2.568-0.496
		            c-3.367-0.673-7.051-1.151-11.375-1.151c-36.957,0-60.396,26.876-68.121,37.258v-37.258H244.49v194.881h63.391V333.073
		            c0,0,47.907-66.721,68.12-17.715c0,43.739,0,124.014,0,124.014h63.371V307.863C439.372,278.418,419.194,253.88,391.964,246.776z"/>
	            <circle cx="186.912" cy="186.912" r="31.005"/>
            </g>
        </SvgIcon>
    ); 
}

export default LinkedInIcon;