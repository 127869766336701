import React from "react";
import { makeStyles, Theme, createStyles, Typography, LinearProgress } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles/withStyles";

const useStyles = (props: ScoreItem_Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%",
        paddingTop: 20,
        paddingBottom: 20
    },
    title: {
        color: "#8d9097"
    },
    progress: {
        height: 5,
        borderRadius: 2
    }
}));


export interface ScoreItem_Props {
    title: string,
    percentage: number,
    classes?: ClassNameMap
}

const ScoreItem = (props: ScoreItem_Props) => {
    const classes = useStyles(props)(props);

    return (
        <div className={classes.root}>
            <Typography className={classes.title} variant="body1">{props.title}</Typography>
            <LinearProgress className={classes.progress} variant="determinate" value={props.percentage} />
        </div>
    );
}

export default ScoreItem;