import React from "react";
import PageContainer from "../../components/PageContainer";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import ProfileCard from "../../components/profile/ProfileCard";
import DownloadResume from "../../components/download_resume/DownloadResume";
import ProficiencyCard from "../../components/proficiencies/ProficiencyCard";
import ExperienceTimeline from "../../components/timelines/instances/experiences/ExperienceTimeline";
import EducationTimeline from "../../components/timelines/instances/educations/EducationTimeline";
import ContactFormCard from "../../components/contact_me/ContactFormCard";

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        backgroundColor: "#efefef",
    }
}));


export const ANCHOR_ABOUT = "about";
export const ANCHOR_RESUME = "resume";
export const ANCHOR_PROFICIENCY = "proficiency";
export const ANCHOR_EXPERIENCE = "experience";
export const ANCHOR_EDUCATION = "education";
export const ANCHOR_CONTACT = "contact";

interface Props {

}

const HomePage = (props: Props) => {
    const classes = useStyles(props)();

    return (
        <div className={classes.root}>
            <PageContainer>
                <ProfileCard anchorId={ANCHOR_ABOUT}/>
                <DownloadResume anchorId={ANCHOR_RESUME}/>
                <ProficiencyCard anchorId={ANCHOR_PROFICIENCY}/>
                <ExperienceTimeline anchorId={ANCHOR_EXPERIENCE}/>
                <EducationTimeline anchorId={ANCHOR_EDUCATION} />
                <ContactFormCard anchorId={ANCHOR_CONTACT} />
            </PageContainer>
        </div>
    );
}

export default HomePage;
