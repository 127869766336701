import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { AbstractValidatorBuilder } from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../resources/values/strings";
import TextInput, { Size } from "../../generic/inputs/TextInput";

const useStyles = makeStyles(theme => createStyles({
    root: {
       
    }
}));

const FIELD_NAME = "name";
const FIELD_EMAIL = "email";
const FIELD_MESSAGE = "message";

interface Props {
    onFieldChange: (id: string, value: string, error: string | null) => void
}

export interface ContactForm_Data {
    [FIELD_NAME]?: string,
    [FIELD_EMAIL]?: string,
    [FIELD_MESSAGE]?: string
}

const ContactForm = (props: Props) => {
    const classes = useStyles();

    const onFieldChange = (id: string, value: string, error: string | null) => {
        props.onFieldChange(id, value, error);
    }

    const size_half: Size = { xs: 12, sm: 6 }
    const size_full: Size = { xs: 12} 

    return (
        <div className={classes.root}> 
            <Grid container xs={12}>
                <TextInput id={FIELD_NAME} size={size_half} label="Name" onFieldChange={onFieldChange} validator={new AbstractValidatorBuilder().required().maximum(64).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build()} />
                <TextInput id={FIELD_EMAIL} size={size_half} label="Email" onFieldChange={onFieldChange} validator={new AbstractValidatorBuilder().required().email().build()} />
                <TextInput id={FIELD_MESSAGE} multiline numberOfLines={15} size={size_full} label="Message" onFieldChange={onFieldChange} validator={new AbstractValidatorBuilder().required().maximum(1024).minimum(5).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build()} />
            </Grid>
        </div>
    );
}

export default ContactForm;