import React from "react";
import { makeStyles, Theme, createStyles} from "@material-ui/core";
import ScoreCard from "../ScoreCard";

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%"
    },
    title: {
        textAlign: "center",
        marginBottom: 30
    }
}));


interface Props {
    component?: "card" | "div"
}

const PlatformScoreCard = (props: Props) => {
    const classes = useStyles(props)();

    const getItems = () => {
        return [
            {title: ".NET Core", percentage: 90},
            { title: "React", percentage: 70 },
            {title: ".NET Framework", percentage: 75},
            { title: "React Native", percentage: 55 },
            { title: "Azure", percentage: 30 },
            { title: "Spring Boot", percentage: 55 },
            { title: "Android Native", percentage: 60 },
            { title: "iOS", percentage: 30 },
            { title: "Docker", percentage: 40 },
        ];
    }

    return (
        <ScoreCard component={props.component} items={getItems()} />
    );
}

export default PlatformScoreCard;