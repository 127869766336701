import React from "react";
import { Card, makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import ProficiencyTabs from "./ProficiencyTabs";
import { withScrollableAnchor, WithScrollableAnchor_Props_Injected } from "../../generic/scroll_anchor/WithScrollableAnchor";

const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
    },
    title: {
        textAlign: "center",
        marginBottom: 30
    }
}));


interface Props extends WithScrollableAnchor_Props_Injected {

}

const ProficiencyCard = (props: Props) => {
    const classes = useStyles(props)();

    return (
        <div className={classes.root}>
            <Typography variant="h3" className={classes.title}>Proficiencies</Typography>
            <Card elevation={3}>
               <ProficiencyTabs />
            </Card>
        </div>
    );
}

export default withScrollableAnchor(ProficiencyCard);