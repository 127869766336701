import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";


const useStyles = (props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        width: 320,
    },
    list_item: {
        width: "100%"
    },
    list: {
        width: "100%"
    },  
    icon: {
        color: "#C1C1C1",
        marginRight: 20
    },
    title: {
        marginTop: 20,
        fontSize: 30,
        fontWeight: 600,
        lineHeight: 1.1,
        textAlign: "center",
        marginBottom: 10
    }, 
    subtitle: {
        fontSize: 16,
        lineHeight: 1.1,
        paddingBottom: 20,
        fontWeight: 400,
        textAlign: "center",
        borderBottom: "1px solid #EDEDED"
    }
}));

interface Item {
    id: string,
    title: string,
    icon: React.ComponentType<SvgIconProps>
}

interface Props {
    onClick: (id: string) => void,
    items: Array<Item>
}

const PageDrawer = (props: Props) => {
    const classes = useStyles(props)(props);
      
    const onItemClick = (item: Item) => {
        props.onClick(item.id);
    }

    const renderIcon = (item: Item) => {
        const Icon = item.icon;
        return <Icon className={classes.icon} />
    }

    return (
        <div className={classes.root}>
            <Typography variant="h3" className={classes.title}>Teun Kooijman</Typography>
            <Typography variant="h4" className={classes.subtitle}>Experienced Software Engineer</Typography>

            <List className={classes.list}>
                {props.items.map(item => (
                    <ListItem className={classes.list_item} button key={item.id} onClick={() => onItemClick(item)}>
                        {renderIcon(item)}
                        <ListItemText primary={item.title} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default PageDrawer;