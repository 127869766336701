import React from "react";
import { SvgIcon, makeStyles, createStyles, Theme } from "@material-ui/core";
import { LogoProps } from "./LogoProps"; 



const useStyles = (props: LogoProps) => makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: props.fontSize, 
        height: "auto"
    }
}));    

const LogoDiamondAgile = (props: LogoProps) => {
    const classes = useStyles(props)();

    return (
        <SvgIcon className={classes.root} x="0px" y="0px" width="224.997px" height="85px" viewBox="0 0 224.997 85" enable-background="new 0 0 224.997 85">
			<path fill="#AEADAE" d="M105.383,1.537l23.498,29.201L84.137,71.305L13.465,49.842l8.625-35.705L105.383,1.537 M105.593,1
				l-83.91,12.692l-8.814,36.49l71.394,21.683l45.304-41.074L105.593,1L105.593,1z"/>
			<path d="M95.407,14.401l11.232,8.544L58.25,81.427L0.575,43.443l6.689-25.385L95.407,14.401 M95.566,13.894l-88.691,3.68
				L0,43.663l58.35,38.428l49.006-59.229L95.566,13.894L95.566,13.894z"/>
			<path fill="#454545" d="M108.304,7.726l11.468,23.327L77.501,63.528L28.242,40.379l3.954-20.344L108.304,7.726 M108.589,7.173
				L31.771,19.597l-4.095,21.069L77.564,64.11l42.837-32.91L108.589,7.173L108.589,7.173z"/>
			<path d="M94.68,21.78l21.668,16.084L90.453,80.445L10.538,33.324l-0.325-9.445L94.68,21.78 M94.839,21.276L9.696,23.392
				l0.352,10.224l80.576,47.512l26.389-43.393L94.839,21.276L94.839,21.276z"/>
			<path fill="#525352" d="M107.902,16.551l3.476,10.094L71.259,77.752L8.526,44.345l14.778-13.403L107.902,16.551 M108.236,15.987
				l-85.16,14.486L7.664,44.452l63.73,33.938l40.545-51.65L108.236,15.987L108.236,15.987z"/>
		    <path d="M122.204,59.694c-0.746-0.46-1.314-1.13-1.703-2.011s-0.584-1.938-0.584-3.172v-3.187c0-1.243,0.189-2.305,0.57-3.187
			    c0.379-0.881,0.934-1.551,1.662-2.012c0.727-0.458,1.602-0.688,2.623-0.688c0.852,0,1.635,0.224,2.344,0.668
			    c0.709,0.446,1.283,1.071,1.719,1.879l-0.293,2.95c0-0.611-0.111-1.136-0.334-1.572c-0.221-0.437-0.545-0.768-0.965-0.995
			    c-0.424-0.227-0.938-0.341-1.539-0.341c-0.936,0-1.662,0.293-2.178,0.876c-0.514,0.585-0.771,1.405-0.771,2.464v3.145
			    c0,1.048,0.258,1.857,0.771,2.429c0.516,0.568,1.242,0.854,2.178,0.854c0.602,0,1.115-0.118,1.539-0.354
			    c0.42-0.236,0.744-0.577,0.965-1.023c0.223-0.444,0.334-0.978,0.334-1.599l0.195,3.046c-0.287,0.714-0.758,1.313-1.412,1.796
			    s-1.459,0.724-2.414,0.724C123.854,60.384,122.952,60.154,122.204,59.694z M128.542,39.902h2.84v20.231h-2.84V39.902z"/>
		    <path d="M135.694,39.902h2.84v2.838h-2.84V39.902z M135.694,45.689h2.84v14.443h-2.84V45.689z"/>
		    <path d="M143.471,59.234c-0.881-0.736-1.322-1.856-1.322-3.358c0-1.43,0.406-2.501,1.211-3.215
			    c0.807-0.714,2.027-1.071,3.66-1.071h4.105l0.193,2.281h-4.283c-0.744,0-1.299,0.165-1.664,0.494
			    c-0.367,0.329-0.551,0.833-0.551,1.511c0,0.731,0.244,1.275,0.73,1.626c0.488,0.354,1.227,0.53,2.221,0.53
			    c1.084,0,1.895-0.116,2.428-0.349c0.533-0.231,0.801-0.594,0.801-1.085l0.291,2.032c-0.25,0.389-0.559,0.709-0.926,0.959
			    s-0.791,0.438-1.271,0.564c-0.482,0.124-1.027,0.188-1.629,0.188C145.684,60.342,144.352,59.974,143.471,59.234z M151,50.977
			    c0-0.944-0.254-1.681-0.76-2.204c-0.504-0.525-1.221-0.787-2.148-0.787c-0.576,0-1.139,0.089-1.691,0.265
			    c-0.551,0.176-1.041,0.423-1.467,0.738l-2.074-1.42c0.547-0.659,1.268-1.171,2.164-1.538c0.895-0.366,1.889-0.55,2.984-0.55
			    c1.197,0,2.221,0.212,3.074,0.633c0.854,0.423,1.504,1.035,1.947,1.838c0.445,0.802,0.668,1.769,0.668,2.9v9.281H151V50.977z"/>
		    <path d="M158.012,45.689h2.84v14.443h-2.84V45.689z M166.639,51.381c0-1.021-0.25-1.81-0.752-2.365
			    c-0.5-0.558-1.211-0.836-2.129-0.836c-0.928,0-1.643,0.246-2.148,0.737c-0.506,0.493-0.758,1.184-0.758,2.073l-0.293-2.782
			    c0.443-0.871,1.025-1.544,1.746-2.017c0.719-0.474,1.514-0.711,2.385-0.711c1.012,0,1.875,0.23,2.588,0.689
			    c0.715,0.459,1.26,1.129,1.637,2.01c0.375,0.883,0.564,1.943,0.564,3.188v8.766h-2.84V51.381z M175.266,51.381
			    c0-1.021-0.266-1.81-0.793-2.365c-0.529-0.558-1.271-0.836-2.227-0.836c-0.883,0-1.564,0.27-2.045,0.809
			    c-0.482,0.537-0.723,1.298-0.723,2.28l-0.572-2.782c0.455-0.955,1.057-1.694,1.801-2.219c0.748-0.524,1.57-0.787,2.471-0.787
			    c1.039,0,1.928,0.23,2.664,0.689c0.738,0.459,1.299,1.129,1.684,2.01c0.385,0.883,0.578,1.943,0.578,3.188v8.766h-2.838V51.381z"/>
		    <path d="M184.743,59.604c-0.891-0.491-1.572-1.208-2.039-2.149c-0.469-0.941-0.703-2.075-0.703-3.402v-2.351
			    c0-1.309,0.234-2.429,0.703-3.36c0.467-0.933,1.148-1.643,2.039-2.129c0.891-0.487,1.961-0.731,3.213-0.731
			    s2.324,0.244,3.215,0.731c0.891,0.486,1.57,1.196,2.039,2.129c0.467,0.932,0.701,2.052,0.701,3.36v2.392
			    c0,1.319-0.234,2.443-0.701,3.375c-0.469,0.933-1.148,1.645-2.039,2.136c-0.891,0.492-1.963,0.738-3.215,0.738
			    S185.633,60.096,184.743,59.604z M190.258,56.71c0.543-0.621,0.814-1.493,0.814-2.617v-2.392c0-1.123-0.271-1.99-0.814-2.603
			    c-0.541-0.612-1.311-0.919-2.303-0.919s-1.76,0.307-2.303,0.919s-0.814,1.479-0.814,2.603v2.392c0,1.124,0.271,1.996,0.814,2.617
			    c0.543,0.622,1.311,0.933,2.303,0.933S189.717,57.332,190.258,56.71z"/>
		    <path d="M197.946,45.689h2.84v14.443h-2.84V45.689z M206.713,51.381c0-1.021-0.266-1.81-0.793-2.365
			    c-0.531-0.558-1.273-0.836-2.227-0.836c-0.93,0-1.645,0.246-2.15,0.737c-0.506,0.493-0.758,1.184-0.758,2.073l-0.293-2.782
			    c0.445-0.871,1.025-1.544,1.746-2.017c0.719-0.474,1.514-0.711,2.387-0.711c1.566,0,2.781,0.514,3.639,1.539
			    c0.857,1.023,1.287,2.474,1.287,4.348v8.766h-2.838V51.381z"/>
		    <path d="M215.735,59.694c-0.746-0.46-1.314-1.13-1.703-2.011c-0.391-0.881-0.586-1.938-0.586-3.172v-3.187
			    c0-1.243,0.191-2.305,0.57-3.187c0.381-0.881,0.936-1.551,1.664-2.012c0.729-0.458,1.604-0.688,2.621-0.688
			    c0.854,0,1.637,0.224,2.346,0.668c0.709,0.446,1.281,1.071,1.719,1.879l-0.293,2.95c0-0.611-0.111-1.136-0.334-1.572
			    s-0.545-0.768-0.967-0.995c-0.422-0.227-0.936-0.341-1.537-0.341c-0.938,0-1.664,0.293-2.178,0.876
			    c-0.516,0.585-0.773,1.405-0.773,2.464v3.145c0,1.048,0.258,1.857,0.773,2.429c0.514,0.568,1.24,0.854,2.178,0.854
			    c0.602,0,1.115-0.118,1.537-0.354s0.744-0.577,0.967-1.023c0.223-0.444,0.334-0.978,0.334-1.599l0.195,3.046
			    c-0.287,0.714-0.758,1.313-1.412,1.796s-1.459,0.724-2.414,0.724C217.385,60.384,216.481,60.154,215.735,59.694z M222.073,39.902
			    h2.84v20.231h-2.84V39.902z"/>
		    <path d="M121.049,78.396c-0.756-0.632-1.133-1.592-1.133-2.88c0-1.225,0.344-2.143,1.037-2.756
			    c0.691-0.611,1.736-0.917,3.137-0.917h3.518l0.168,1.955h-3.674c-0.637,0-1.111,0.143-1.426,0.424
			    c-0.314,0.282-0.473,0.714-0.473,1.294c0,0.628,0.211,1.094,0.627,1.395c0.418,0.303,1.053,0.454,1.902,0.454
			    c0.93,0,1.625-0.099,2.082-0.298s0.686-0.509,0.686-0.931l0.25,1.741c-0.215,0.333-0.479,0.608-0.793,0.823
			    s-0.678,0.375-1.092,0.482c-0.412,0.107-0.879,0.162-1.395,0.162C122.946,79.345,121.803,79.028,121.049,78.396z M127.5,71.317
			    c0-0.811-0.217-1.44-0.65-1.89c-0.432-0.449-1.047-0.674-1.842-0.674c-0.494,0-0.977,0.075-1.449,0.226
			    c-0.475,0.151-0.893,0.362-1.258,0.632l-1.777-1.216c0.469-0.564,1.088-1.004,1.854-1.317c0.768-0.314,1.621-0.471,2.559-0.471
			    c1.025,0,1.904,0.18,2.637,0.542c0.73,0.361,1.287,0.887,1.67,1.574c0.381,0.688,0.572,1.516,0.572,2.486v7.955H127.5V71.317z"/>
		    <path d="M135.116,78.789c-0.641-0.394-1.127-0.968-1.461-1.723s-0.502-1.662-0.502-2.72v-2.73c0-1.065,0.164-1.977,0.49-2.732
			    c0.326-0.754,0.801-1.329,1.426-1.723c0.623-0.394,1.373-0.591,2.248-0.591c0.73,0,1.4,0.191,2.01,0.573
			    c0.607,0.381,1.098,0.918,1.473,1.61l-0.252,2.527c0-0.523-0.094-0.974-0.285-1.348s-0.467-0.657-0.828-0.852
			    c-0.361-0.195-0.803-0.292-1.318-0.292c-0.805,0-1.426,0.25-1.867,0.75c-0.441,0.501-0.662,1.206-0.662,2.111v2.695
			    c0,0.899,0.221,1.593,0.662,2.081s1.063,0.734,1.867,0.734c0.516,0,0.957-0.102,1.318-0.305s0.637-0.495,0.828-0.876
			    c0.191-0.382,0.285-0.84,0.285-1.372l0.168,2.611c-0.246,0.613-0.65,1.126-1.211,1.539s-1.25,0.621-2.068,0.621
			    C136.53,79.381,135.756,79.183,135.116,78.789z M136.268,84.198c0,0-0.369-0.174-1.105-0.519
			    c-0.734-0.347-1.305-0.833-1.711-1.461l1.646-1.491c0.318,0.438,0.697,0.775,1.139,1.014s0.904,0.357,1.389,0.357
			    c0.932,0,1.65-0.256,2.16-0.769c0.508-0.514,0.762-1.238,0.762-2.177V66.785h2.434v12.01c0,1.137-0.209,2.111-0.625,2.923
			    c-0.418,0.811-1.025,1.426-1.82,1.849c-0.793,0.421-1.752,0.632-2.873,0.632C136.733,84.198,136.268,84.198,136.268,84.198z"/>
		    <path d="M146.678,61.824h2.434v2.434h-2.434V61.824z M146.678,66.785h2.434v12.38h-2.434V66.785z"/>
		    <path d="M155.243,75.969c0,0.302,0.064,0.534,0.195,0.698c0.133,0.162,0.316,0.243,0.555,0.243h1.145v2.314h-1.43
			    c-0.922,0-1.637-0.286-2.141-0.858c-0.506-0.573-0.758-1.379-0.758-2.421V61.824h2.434V75.969z"/>
		    <path d="M163.233,79.345c0,0-0.406-0.215-1.217-0.645c-0.813-0.43-1.432-1.059-1.855-1.891c-0.426-0.831-0.639-1.835-0.639-3.012
			    v-1.396c0-1.224,0.205-2.27,0.615-3.137c0.408-0.865,1.004-1.525,1.783-1.979c0.779-0.454,1.717-0.68,2.814-0.68
			    c1.033,0,1.916,0.258,2.646,0.774c0.732,0.517,1.291,1.264,1.676,2.242c0.387,0.978,0.578,2.151,0.578,3.519v0.812h-8.346v-1.956
			    h6.033c-0.072-1.011-0.332-1.796-0.781-2.356c-0.449-0.56-1.051-0.84-1.807-0.84c-0.922,0-1.637,0.302-2.141,0.906
			    c-0.506,0.604-0.758,1.459-0.758,2.563v1.575c0,1.025,0.27,1.813,0.813,2.36c0.539,0.549,1.307,0.823,2.301,0.823
			    c0.492,0,0.98-0.1,1.467-0.298c0.484-0.199,0.918-0.478,1.301-0.834l1.598,1.597c-0.613,0.589-1.301,1.044-2.064,1.365
			    c-0.762,0.322-1.531,0.484-2.301,0.484C163.803,79.345,163.233,79.345,163.233,79.345z"/>
        </SvgIcon>
    ); 
}

export default LogoDiamondAgile;