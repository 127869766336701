import React from "react";
import { SvgIcon, makeStyles, createStyles, Theme } from "@material-ui/core";
import { IconProps } from "@material-ui/core/Icon";


const useStyles = (props: IconProps) => makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: props.fontSize,
        color: props.color
    }
}));

const LinkedInIcon = (props: IconProps) => {
    const classes = useStyles(props)();

    return (
        <SvgIcon className={classes.root} x="0px" y="0px" width="283.464px" height="283.464px" viewBox="155.908 279.213 283.464 283.464" enable-background="new 155.908 279.213 283.464 283.464">
            <path d="M307.374,562.677c-46.141,0-83.341-14.062-110.564-41.795c-27.139-27.764-40.901-61.35-40.901-99.819
		        c0-40.13,14.457-74.165,42.969-101.161c28.517-27,63.578-40.69,104.211-40.69c38.617,0,71.348,12.015,97.282,35.709
		        c25.881,23.593,39.003,52.553,39.003,86.079c0,26.37-7.982,49.187-23.724,67.821c-15.588,18.505-34.312,27.887-55.652,27.887
		        c-14.298,0-24.786-5.274-32.065-16.127l-2.122-3.162l-2.478,2.893c-9.889,11.549-21.398,17.163-35.187,17.163
		        c-15.575,0-28.542-6.363-39.642-19.453c-11.08-13.011-16.696-30.505-16.696-52.002c0-21.993,6.337-40.14,18.836-53.937
		        c12.426-13.78,27.546-20.765,44.943-20.765c10.811,0,20.935,3.754,30.089,11.158l3.427,2.772l3.031-10.921h32.628l-10.672,86.502
		        c-0.696,6.529-1.033,11.86-1.033,16.346c0,7.163,3.918,11.271,10.75,11.271c10.654,0,20.869-6.509,30.363-19.346
		        c9.341-12.572,14.08-28.479,14.08-47.274c0-27.404-10.238-50.463-30.431-68.537c-20.099-17.991-45.062-27.113-74.195-27.113
		        c-31.611,0-58.639,11.087-80.334,32.955c-21.729,21.9-32.747,49.526-32.747,82.109c0,30.045,10.267,56.588,30.516,78.889
		        c20.343,22.397,49.364,33.755,86.256,33.755c23.063,0,46.959-5.2,71.021-15.455c1.903-0.803,3.911-1.212,5.958-1.212
		        c6.107,0,11.525,3.612,13.803,9.201c3.001,7.365-0.629,15.925-8.091,19.081C363.152,556.898,335.352,562.677,307.374,562.677z
		         M297.789,384.006c-9,0-16.633,4.168-22.688,12.387c-5.825,7.908-8.779,18.372-8.779,31.101c0,10.314,2.119,19.111,6.299,26.144
		        c4.397,7.51,10.404,11.327,17.845,11.327c10.233,0,19.124-4.937,26.423-14.67c7.078-9.498,10.695-20.478,10.759-32.64
		        c0-10.057-3.042-18.261-9.04-24.399C312.613,387.119,305.608,384.006,297.789,384.006z"/>
        </SvgIcon>
    );
}

export default LinkedInIcon;