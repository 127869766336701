import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { ClassNameMap } from "@material-ui/styles/withStyles";
import ReduxState from "../../state/redux/ReduxState";
import { connect } from "react-redux";
import { Theme } from "@material-ui/core";

const padding = 10;
const underlineWidth = "calc(100% - " + (2 * padding) + "px)";

const mapStateToProps = (state: ReduxState) => ({
    activeAnchorId: state.navigation.activeAnchorId
})

const useStyles = (isActive: boolean, props: Props) => makeStyles((theme: Theme) => createStyles({
    root: {
        marginLeft: 12,
        "& :hover": {
            cursor: "pointer",
           
        },
        "&:hover $underline_wrapper": {
            width: underlineWidth + "!important"
        },
        position: "relative",
    },
    title: {
        textTransform: "uppercase", 
        whiteSpace: "nowrap",
        fontSize: 13,
        fontWeight: 700,
        paddingLeft: padding,
        paddingRight: padding,
        paddingBottom: 8,
        marginBottom: 0,
        position: "relative",
        lineHeight: 1,
    },
    underline_wrapper: {
        borderRadius: 1,
        transition: "transform 400ms ease-out 100ms, width 300ms ease-out",
        marginLeft: padding,
        width: isActive ? underlineWidth : 0,
        borderBottom: "3px solid " + theme.palette.primary.main
    }
}));

interface Props {
    id: string,
    onClick: (id: string) => void,
    title: string,
    classes?: ClassNameMap,
    activeAnchorId?: string
}

const PageBarItem = (props: Props) => {
    const classes = useStyles(props.activeAnchorId === props.id, props)(props);

    const onClick = () => {
        props.onClick(props.id);
    }

    return (
        <div className={classes.root} onClick={onClick}>
            <p className={classes.title}>{props.title}</p>
            <div className={classes.underline_wrapper}></div>
        </div>
    );
}

export default connect(mapStateToProps)(PageBarItem);