import { useState, useEffect } from "react";

const useScrollOffset = (direction: "horizontal" | "vertical") => {

    const getCurrentWindowOffset = () => {
        return direction ===  "horizontal" ? window.pageXOffset : window.pageYOffset;
    }

    const [scrollOffset, setScrollOffset] = useState(getCurrentWindowOffset());

    const onScroll = () => {
        const offset = getCurrentWindowOffset();
        setScrollOffset(offset);
    };

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        }
    });

    return () => scrollOffset;
}

export default useScrollOffset;