import { Action } from "redux";

export default class AbstractReduxAction implements Action
{
    public readonly type: string;

    constructor(type: string)
    {
        this.type = type;
    }

    public toPlainObject = (): Action =>
    {
        return Object.assign({}, this);
    }
}